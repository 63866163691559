// import '../adaptivePractice.css';
import { renderedContext, AdaptiveChallengeContext } from '../context/context';
import { useState, useContext } from "react";
import { ReactEvent } from "@mathbuddy/adaptive-practice-events";
import { stylesMap, renderer, IConfig } from "./Render";
import { Player } from "@lottiefiles/react-lottie-player";

export const CheckAnswerAdaptivePractice = ({ className, id, style, children, clickedVal, actualVal, clickable, extraProps }: IConfig) => {

    // console.log(extraProps);
    const state: any = useContext(renderedContext);
    const challengeContext: any = useContext(AdaptiveChallengeContext);
    let exp = state.explanation;
    let hint = state.hint;
    // const adaptivePracticeReducer: IAdaptivePracticeReducer = useSelector((state: IReducer) => state.adaptivePracticeReducer);

    let [clickCount, setClickCount] = useState(0);
    const [showHint, setShowHint] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const [isCorrectAns, setIsCorrectAns] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [showFinish, setShowFinish] = useState(false);

    const onClick = (id: string, clickedVal: any, actualVal: any, extraProps: any) => {
        setShowHint(false)
        extraProps = { ...extraProps, clickCount }
        let answerObj = new ReactEvent({ id, clickedVal, actualVal, extraProps }).handleEvent(extraProps.event);
        setIsCorrectAns(answerObj.isCorrectAns);
        let count = clickCount + 1;
        setClickCount(count);

        if (challengeContext.isChallenge) {
            setDisabled(true);
            if (!answerObj.isCorrectAns)
                setShowExplanation(true);
            if (!answerObj.isCorrectAns && challengeContext.attempted === challengeContext.total - 1)
                setShowFinish(true);
        }
        else {
            let hintStatus = (count === 1 && !answerObj.isCorrectAns) ? true : false;
            let expStatus = (count === 2 && !answerObj.isCorrectAns) ? true : false;
            setShowHint(hintStatus);
            setShowExplanation(expStatus);
            if (count === 2 || answerObj.isCorrectAns)
                setDisabled(true);
        }


        state.saveQuestionResponse(answerObj, count);
    }

    return (<>
        {/* Done Button */}
        {(extraProps.type === "doneButton") && (
            <button id={id} style={style} onClick={() => { onClick(id, clickedVal, actualVal, extraProps) }} disabled={disabled} className={className}>{children}</button>
        )}
        {/* Choices */}
        {(extraProps.type === "choices") && (
            <div id={id} className={className} style={style}>
                {extraProps.children.map((child: any, ii: number) => {
                    return (
                        <button key={ii} id={child.id} className={child.class} style={stylesMap(child.styles)}
                            disabled={disabled} onClick={() => { onClick(child.id, child.clickedVal, child.actualVal, child.extraProps) }}>
                            {child.children.map((val: any) => {
                                let objRendered;
                                objRendered = renderer(val);
                                return objRendered;
                            })}
                        </button>
                    )
                })}

            </div>

        )}

        {/* Explanation */}
        {(disabled && !isCorrectAns) && (
            <div id="explanationBox" className="info-box">
                <div id="explanationContent" className="d-flex info-content explanation">
                    {(showExplanation) && (
                        <button id="downButton" onClick={() => { setShowExplanation(false) }}><img className="exp-down-btn" src={exp.extraProps.downBtnUrl}></img></button>
                    )}
                    {(!showExplanation) && (
                        <button id="upButton" onClick={() => { setShowExplanation(true) }} className="exp-up-btn"><img src={exp.extraProps.upBtnUrl}></img></button>
                    )}
                    <button id="nextButton" onClick={showFinish ? state.onFinish : state.loadContent} className="next-question-btn buttonStyle1">{showFinish ? "Finish" : "Next"}</button>
                    {(showExplanation) && (<>
                        <Player id={"explanationBoy"} className={"info-boy"} autoplay loop speed={1} src={exp.extraProps.expBoyUrl}></Player>
                        {/* <img id="explanationBoy" className="info-boy" src={exp.extraProps.expBoyUrl}></img> */}
                        <div id="explanationText" className="info-text d-flex align-items-start justify-content-start w-100">
                            {
                                exp.children.map((val: any) => {
                                    let objRendered;
                                    // console.log(state.outputJSON.explanation);
                                    objRendered = renderer(val);
                                    return objRendered;
                                })
                            }
                        </div></>)}
                </div>
            </div>
        )}

        {/* Hint */}
        {(showHint) && (
            <div id="hintBox" className="info-box">
                <div id="hintContent" className="d-flex info-content hint ">
                    <button id="closeHintButton" onClick={() => { setShowHint(false) }}><img className="close-hint" src={hint.extraProps.hintClose}></img></button>
                    <Player id={"hintBoy"} className={"info-boy"} autoplay loop speed={1} src={hint.extraProps.hintBoyUrl}></Player>
                    {/* <img id="hintBoy" className="info-boy" src={hint.extraProps.hintBoyUrl}></img> */}
                    <div id="hintText" className="info-text d-flex align-items-start justify-content-start w-100">
                        {
                            hint.children.map((val: any) => {
                                let objRendered;
                                objRendered = renderer(val);
                                return objRendered;
                            })
                        }
                    </div>
                </div>
            </div>
        )}
    </>)

}