import React, { useContext, useEffect, useState } from 'react'
import "./mentalMath.css";
import components from "../../assets/data/components.json";
import MentalQuiz from './MentalQuiz';
import { Content } from '@mathbuddy/adaptive-practice-engine';
import AppLoader from '../../components/atoms/animation/Animation';
import { BackButton, MainButton } from '../../components/atoms/button/Button';
import { MainHeader } from '../../components/atoms/header/Header';
import { useLocation, useNavigate } from "react-router-dom";
import { FALock, FAStar } from '../../components/atoms/fontawesomeIcon/Fontawesome';
import apis from '../../apis/api';
import { ICurrentUser } from '../../interface/interface';
import { StatusCodes } from 'http-status-codes';
import { decryptData, isFlatPanel, isMobileDevice, isTokenValid } from '../../utils/utils';
import { ls } from '../../localStorage';
import { AppContext } from '../../context/context';

interface IMMQuery {
  skill: { loId: string, name: string },
  currentUser: ICurrentUser,
  currentStreak: number,
  token: string,
  dbToken: string
  isLandscape?: boolean
}

interface ImentalMath {
  id: string;
  level: number;
  order: number;
  stars: number;
  lock: boolean;
}

const MentalMath = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const QUERY: IMMQuery | any = queryParams.get("query");

  const state: any = useContext(AppContext);

  const content = new Content();
  const [currentUser, setCurrentUser]: ICurrentUser | any = useState({});
  const [skill, setSkiil] = useState({ loId: '', name: '', fetch: false });
  const [currentStreak, setCurrentStreak] = useState(0);

  const [loader, setLoader] = useState(false);
  const [levelList, setLevelList] = useState([]);
  const [currentLevel, setCurrentLevel]: any = useState({})
  const [currentLevelQuestions, setCurrentLevelQuestions] = useState([]);
  const [isMentalMathInfo, setIsMentalMathInfo] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [highScore, setHighScore]: any = useState({ currentUser: 0, topUser: 0 });
  const [isMentalMathStarted, setIsMentalMathStarted] = useState(false);
  const [disableLandscape, setDisableLandscape] = useState(false);

  useEffect(() => {
    verifyAndSetData();
  }, []);

  useEffect(() => {
    let disable = (!isLandscape && isMobileDevice() && state.windowHeight < state.windowWidth) ? true : false;
    setDisableLandscape(disable);
  }, [state.windowHeight, state.windowWidth, isLandscape]);

  useEffect(() => {
    if (skill.fetch)
      fetchLevels();
  }, [skill.fetch]);
  const verifyAndSetData = async () => {
    try {
      // console.log(QUERY);
      let query: IMMQuery = decryptData(QUERY);
      // console.log(isTokenValid(query.token));
      // console.log(query);

      if (await isTokenValid(query.token) && await ls.isValid(query.skill) &&
        await ls.isValid(query.currentUser)) {
        // console.log("setLS")
        ls.setToken(query.token);
        ls.setDBToken(query.currentUser.dbToken);
        setCurrentUser(query.currentUser);
        setCurrentStreak(query.currentStreak)
        if (query.isLandscape && query.isLandscape === true)
          setIsLandscape(true);
        setSkiil({ ...query.skill, fetch: true });
      }
      else {
        console.log("error while setting local storage");
        state.messageForParent('close');
        navigate('/pageNotFound');
      }
    } catch (error) {
      console.log(error);
      state.messageForParent('close');
      navigate('/pageNotFound');
    }
  }

  const fetchLevels = async () => {
    setLoader(true);
    await content.loadSkillBySkillId(skill.loId, true);
    let levels = await content.getUniqueQuestionsForSkill();
    let playedData = await apis._getMentalMathLevel({
      user_id: currentUser.id, classroom_id: currentUser.classroom_id,
      lo_id: skill.loId
    })
    let count0 = 0;
    if (playedData.status === StatusCodes.OK) {
      levels.map((level: ImentalMath) => {
        const thisLevel = playedData.data.result.filter((pd: { question_id: string }) => pd.question_id.toString() === level.id.toString());
        const isPlayed = thisLevel.length > 0 ? true : false;
        level.stars = isPlayed ? thisLevel[0].stars : 0;
        level.lock = false;
        if (currentUser.role === 'student')
          level.lock = level.stars > 0 ? false : count0 === 0 ? false : true;

        if (level.stars === 0)
          count0++;
      })
    }
    setLevelList(levels);
    setLoader(false);
  };

  const onClickLevel = async (level: ImentalMath) => {
    if (!level.lock) {
      setCurrentLevel(level);
      setLoader(true);
      let result = await apis._getMentalMathHighScore(skill.loId, level.id);

      let currentUser = (result.data.result.currentUser &&
        result.data.result.currentUser.high_score)
        ? result.data.result.currentUser.high_score.value : 0;

      let topUser = (result.data.result.topUser &&
        result.data.result.topUser.high_score)
        ? result.data.result.topUser.high_score.value : 0;

      setHighScore({ currentUser, topUser });
      setLoader(false);
      setIsMentalMathInfo(true);
    }
  };

  const onStartLevel = async () => {
    const APP_OR_WEB = (state.windowHeight > state.windowWidth) ? 'APP' : 'WEB';
    await content.loadSkillBySkillId(skill.loId, true);
    const res = await content.getQuestionsForMentalMath(currentLevel.id, 'REACT_' + APP_OR_WEB);
    console.log(res);
    setCurrentLevelQuestions(res);
    setIsMentalMathInfo(false);
    setIsMentalMathStarted(true);
  };

  const onTryAgainMentalMath = () => {
    setIsMentalMathInfo(true);
    setIsMentalMathStarted(false);
  };
  const onCloseMentalMath = () => {
    setIsMentalMathInfo(false);
    setIsMentalMathStarted(false);
  };

  // const messageForParent = (type: string, data: any = {}) => {
  //   window.parent.postMessage(JSON.stringify({ type, data }), '*');
  // }
  return (
    <div id="mentalMathWrapper" className="mentalMathWrapper d-flex flex-column flex-sm-row" >
      {(disableLandscape) && (
        <div className="mentalMathLandscapeContainer d-flex flex-column justify-content-center align-items-center">
          <h4>Mental Math works only in portrait mode</h4>
          <span> Please rotate your device! <br></br>
            (If you are not able to rotate, check your mobile settings and ensure <br></br>
            "Auto-rotate screen" is switched on)
          </span>
        </div>
      )}
      {(!disableLandscape) && (<>
        {loader && <AppLoader></AppLoader>}
        {!isMentalMathInfo && !isMentalMathStarted && (
          <div className="mentalMath d-flex flex-column align-items-center justify-content-start">
            <BackButton onClick={() => { state.messageForParent('close') }} ></BackButton>
            <div className='w-75 d-flex align-items-center justify-content-center'><MainHeader text={skill.name} ></MainHeader></div>
            <div className="mentalMathLevelList d-flex flex-column align-items-center">
              {levelList.map((obj: ImentalMath, ii: number) => {
                return (
                  <div className={`mentalMathLevelCard d-flex ${(obj.lock) ? "mentalMathLockLevelCard" : ""}`}
                    key={ii} onClick={() => onClickLevel(obj)}
                  >
                    <div className="mentalMathtextWrapper">
                      <div className="levelName">
                        Level {ii + 1}
                      </div>
                    </div>
                    {(!obj.lock) && (
                      <div className="scored_stars">
                        {([1, 2, 3].map((n: number) => {
                          return (
                            <FAStar key={n} color={obj.stars >= n ? "orange" : ""}></FAStar>
                          )
                        }))}
                      </div>
                    )}
                    {(obj.lock) && (
                      <div className="scored_stars">
                        <FALock color=''></FALock>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
        {isMentalMathInfo && !isMentalMathStarted && (
          <div id="mentalMathInfoPage" className="mentalMath d-flex flex-column align-items-center justify-content-start">
            <BackButton onClick={() => { setIsMentalMathInfo(false) }} ></BackButton>
            <MainHeader text="Mental Math"></MainHeader>
            <div className="mentalMathLevelList d-flex flex-column align-items-center">
              <div id="mentalMathLevelInfo">
                <h1>It is time for Mental Math.<br></br> Are you ready?</h1>
                <img id="clock1" src={components.baseURL + "stopWatchWatermarkDOWN.svg"} alt="Stop watch down" ></img>
                <img id="clock2" src={components.baseURL + "stopWatchImage.svg"} alt="Stop watch"></img>
                <img id="clock3" src={components.baseURL + "stopWatchWatermarkUP.svg"} alt="Stop watch up" ></img>
              </div>
              <div id="highScoreContainer" className='d-flex flex-column align-items-center justify-content-evenly'>
                <div className='d-flex align-items-center justify-content-center'><p id="mid_line1"> Your high score in this level</p>&nbsp;&nbsp;<span>{highScore.currentUser}</span></div>
                <div className='d-flex align-items-center justify-content-center'><p id="mid_line2"> Overall high score in this level</p>&nbsp;&nbsp;<span>{highScore.topUser}</span></div>
              </div>
              <div id="startBtn">
                <MainButton text="Start" variant={2} active={true} height={'100%'} width={"100%"} onClick={onStartLevel}></MainButton>
              </div>
              <div id="infoNote">
                <p id="note1">You have 1 minute to answer as many questions as possible.</p>
                {(currentLevel.stars === 0) && (
                  <>
                    <p id="note2"> You have to answer a minimum of 10 questions </p>
                    <p id="note3"> You cannot make more than 2 mistakes </p>
                  </>
                )}
                {(currentLevel.stars > 0) && (
                  <>
                    <p id="note4"> You have completed this level and hence cannot make more than 1 mistake.</p>
                    <p id="note5"> You have to answer a minimum of 10 questions to improve your stars.</p>
                  </>)}
              </div>
            </div>
          </div>
        )}
        {!isMentalMathInfo && isMentalMathStarted && (
          <MentalQuiz
            loId={skill.loId}
            currentLevel={currentLevel}
            questions={currentLevelQuestions}
            currentUser={currentUser}
            currentStreak={currentStreak}
            onTryAgain={onTryAgainMentalMath}
            onClose={onCloseMentalMath}
            msgToParent={state.messageForParent} />
        )}
      </>
      )}
    </div>
  )
}

export default MentalMath