import jwt_decode from "jwt-decode";
import { ls } from "../localStorage";
import CryptoJS from 'crypto-js';

export const toggleFullSceen = (type: string) => {
    console.log('toggleFullSceen');
    let doc: any = document;
    let docEl: any = document.documentElement;
    let requestFullScreen = docEl.requestFullscreen || docEl.webkitRequestFullscreen || docEl.webkitEnterFullScreen ||
      docEl.mozRequestFullscreen || docEl.msRequestFullscreen;
    let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen || doc.msExitFullscreen;
    let fullScreen = doc.fullscreenElement || doc.mozFullScreenElement ||
      doc.webkitFullscreenElement || doc.msFullscreenElement;
    if (requestFullScreen == undefined)
      return
    if (!fullScreen && type === 'fullScreen') {
      requestFullScreen.call(docEl);
    }
    else {
      if (fullScreen && cancelFullScreen)
        cancelFullScreen.call(doc);
    }
  }

export const isTokenValid=(token:string|any)=>{
    try {
        if(ls.isValid(token))
        {
            let decode_token:any =jwt_decode(token)
            var currentTimeSeconds = Math.round(+new Date() / 1000);
            return (decode_token.exp < currentTimeSeconds)?false:true;   
        }
        else
        {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getTimeSpent = (startTime:any) => {
    const endTime = new Date()
    return Math.round(((endTime.getTime() - startTime.getTime()) / 1000) + 0.5);
  }
  
export const isTV=()=>{
    let regexp = /tv|jiopages/i;
    return regexp.test(navigator.userAgent.toLowerCase());
} 

export const isMobileDevice=()=>{
    let regexp = /android|iphone|kindle|ipad/i;
    return regexp.test(navigator.userAgent.toLowerCase());
} 

export const isFlatPanel = () => {
    let regexp = /SMART-TV|Tizen|WebOS|BRAVIA|Viera/i;
    return regexp.test(navigator.userAgent.toLowerCase());
}
  
export const encryptData = (data: {}) => {
    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || '';
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
    return encrypted;
  }

export const decryptData = (encrypted:string) => {
    const SECRET_KEY=process.env.REACT_APP_SECRET_KEY || '';
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
  }