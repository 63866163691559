import React from "react";
import {ReactEvent} from "@mathbuddy/adaptive-practice-events";
import { isMobileDevice, isTV } from "./Render";
import { IConfig } from "./Render";

export const Div = ({ className, id, children, draggable, style, extraProps }:IConfig) => {

    const handleEvent=(type:string, event:any)=>{
        const reactEvent = new ReactEvent({id, draggable, extraProps});
        if (extraProps!==null && extraProps!==undefined)
            reactEvent.handleEvent(extraProps.event[type], event);
    }

    let isTv =isTV();
    let isMobile =isMobileDevice();

    return ( <div id = {id}
        className = { className }
        style = { style }
        draggable = { draggable && !isTv }
        onClick = {extraProps && extraProps.isClickable ? (e) => handleEvent("remove",e): undefined}

        onDragStart = {!isMobile && !isTv ?(e) =>handleEvent("dragStart",e):undefined}
        onDrop =      {!isMobile && !isTv && extraProps ?(e) =>handleEvent("drop",e) : undefined }
        onDragOver =  {(!isMobile && !isTv && extraProps && extraProps.dragOver) ? (e) =>handleEvent("dragOver",e) : undefined} 

        onMouseDown = {(isTv &&  draggable) ? (e) => handleEvent("mouseDown",e):undefined}
        onMouseOver = {(isTv && extraProps && extraProps.dragOver)  ? (e) => handleEvent("mouseOver",e) : undefined }

        onTouchStart = {draggable ? (e) => handleEvent("touchStart",e):undefined}
        onTouchMove =  {draggable? (e) => handleEvent("touchMove",e): undefined} 
        onTouchEnd =   {draggable ? (e) => handleEvent("touchEnd",e) : undefined }

        >{ children }</div>
    );
}