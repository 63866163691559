const initialState = {
  currentUser: {},
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_INITIAL_STATE":
      return { ...initialState };
    case "SET_CURRENT_USER":
      return { ...state, currentUser: action.payload };
    default:
      return state;
  }
}

export default userReducer