import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faSpinner, faThumbsUp, faCheckCircle, faStar, faRefresh,
    faLock, faHeart, faHeartBroken, faAngleLeft, faAngleRight,
    faExpand
} from "@fortawesome/free-solid-svg-icons";
library.add(faSpinner, faThumbsUp, faStar, faLock, faHeart,
    faRefresh, faHeartBroken, faAngleLeft, faAngleRight, faExpand);
interface IIcon {
    color?: string,
    size?: SizeProp
}


export function FASpinner(props: IIcon) {
    return (<div className='w-100 d-flex align-items-center justify-content-center' style={{ height: '100%' }}><FontAwesomeIcon icon={faSpinner as IconProp} spin={true} size="xl" color={props.color ? props.color : 'var(--secondary-purple)'} /></div>)
}

export function FAThumbsUp(props: IIcon) {
    return (<FontAwesomeIcon icon={faThumbsUp as IconProp} size="lg" color={props.color ? props.color : 'var(--secondary-green)'} />)
}

export function FAThumbsUpCircle(props: IIcon) {
    return (<FontAwesomeIcon icon={faThumbsUp as IconProp} size="lg" style={{ border: '1px solid #14CB12', borderRadius: '25px', backgroundColor: '#14CB12', padding: '5px 5px 5px 7px', transform: 'rotateY(180deg)' }} color={props.color ? props.color : 'white'} />)
}

export function FATickMark(props: IIcon) {
    return (<FontAwesomeIcon icon={faCheckCircle as IconProp} size="lg" color={props.color ? props.color : '#14CB12'} />)
}

export function FAStar(props: IIcon) {
    return (<FontAwesomeIcon icon={faStar as IconProp} size="lg" color={props.color ? props.color : 'grey'} />)
}

export function FALock(props: IIcon) {
    return (<FontAwesomeIcon icon={faLock as IconProp} size="lg" color={props.color ? props.color : 'grey'} />)
}

export function FAHeart(props: IIcon) {
    return (<FontAwesomeIcon icon={faHeart as IconProp} size="lg" color={props.color ? props.color : 'grey'} />)
}

export function FAHeartBroken(props: IIcon) {
    return (<FontAwesomeIcon icon={faHeartBroken as IconProp} size="lg" color={props.color ? props.color : 'grey'} />)
}

export function FARefresh(props: IIcon) {
    return (<FontAwesomeIcon icon={faRefresh as IconProp} size={props.size ? props.size : "lg"} color={props.color ? props.color : 'grey'} />)
}

export function FAAngleLeft(props: IIcon) {
    return (<FontAwesomeIcon icon={faAngleLeft as IconProp} size={props.size ? props.size : "sm"} color={props.color ? props.color : 'grey'} />)
}

export function FAAngleRight(props: IIcon) {
    return (<FontAwesomeIcon icon={faAngleRight as IconProp} size={props.size ? props.size : "sm"} color={props.color ? props.color : 'grey'} />)
}

export function FAExpand(props: IIcon) {
    return (<FontAwesomeIcon icon={faExpand as IconProp} size={props.size ? props.size : "lg"} color={props.color ? props.color : 'grey'} />)
}