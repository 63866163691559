import React, { useEffect, useState } from "react";
import { isMobileDevice, isTV } from "./Render";
export const Input = ({className, size, type, id, children, style, onChange, onKeyDown,extraProps})=>
{
    let textInput = null; 
  
    useEffect(()=>{
    if(className.includes('focusTextBox'))
     textInput.focus();
  })

  const [num, setNum] = useState('');

  const handleNumChange = event => {
    setNum(event.target.value.slice(0, size));
  };
    
    return (
        <input id={id} className={className} style={style} value={num} onChange={handleNumChange}
        placeholder={children} maxLength={size} size={size} type="text" 
        autoFocus autoComplete="off" onKeyDown={onKeyDown} 
        direction={extraProps&&extraProps.direction?extraProps.direction:1}
        position={extraProps&&extraProps.position?extraProps.position:null}
        onWheel={(e) => e.target.blur()} readOnly={isMobileDevice() || isTV()}
        ref={(input) => { textInput = input; }}/>
     );
}