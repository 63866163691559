import { Player } from "@lottiefiles/react-lottie-player";
import './animation.css'

export default function AppLoader(props: { isInitial?: Boolean }) {

      return (<div id="AppLoaderPopup" className="popupv2 d-flex flex-column align-items-center justify-content-center" style={{ backgroundColor: props.isInitial ? 'var(--sky-blue)' : 'rgb(205, 225, 255,.8)' }}>
            <div className="loader-animation d-flex align-items-center justify-content-center mb-4" style={{ backgroundColor: props.isInitial ? 'var(--sky-blue)' : 'rgb(205, 225, 255,.8)' }}>
                  <Player id={"AppLoaderAnimation"} autoplay loop style={{ "width": "100%", "height": "100%" }} speed={1} src={"../assets/animation/appLoader.json"}></Player>
            </div>
            {(props.isInitial) && (<>
                  <span className="loader-logo-text">Math Buddy</span>
                  <span className="loader-logo-bottom-text">You can count on me...! </span>
            </>)}
      </div>)
}

export function ConfettiAnimation() {
      return (
            <div style={{ maxHeight: "50vh", maxWidth: '70vw', top: 0, position: 'absolute', zIndex: 20 }}>
                  <Player id={"player"} autoplay style={{ "width": "100%", "height": "100%" }} speed={1} src={"../assets/animation/Confetti.json"}></Player>
            </div>
      )
}