import React from "react";
import { Div } from './Div';
import { Button } from './Button';
import { Input } from "./Input";
import { Table } from "./Table";
import { Thead } from "./Thead";
import { Tbody } from "./Tbody";
import { Th } from "./Th";
import { Tr } from "./Tr";
import { Td } from "./Td";
import { Img } from "./Img";
import { Svg } from "./Svg";
import { Circle } from "./Circle";
import { Rectangle } from "./Rectangle";
import { Polygon } from "./Polygon";
import { Ellipse } from "./Ellipse";
import { Span } from "./Span";
import { Br } from "./Br";
import { CheckAnswer } from "./CheckAnswer";
import { Lottie } from "./Lottie";
import { Cube } from "./Cube";
import { Path } from "./Path";
import { Polyline } from "./Polyline";
import { Text } from "./Text";
import { SvgG } from "./SvgG";
import { Fragment } from "./Fragment";
import { SvgLine } from "./SvgLine";
import { SvgMarker } from "./SvgMarker";
import { TSpan } from "./Tspan";

export interface IConfig{component:string,
id:string, key:string,text?: string,style:any,size: number, 
type?: any,colspan?:any,onChange?:any,onKeyDown?: any,className?: string,draggable?:boolean,
src?:string, cx?:string, cy?:string, radius?:string, height?:any, width?:any,
fill?:string, stroke_width?: any, stroke?:any, points?:any, rx?:any, ry?:any,
clickedVal?:any, actualVal?: any, clickable?:any, viewBox?:any, extraProps?:any,children:any
}
export interface IStyle{
    name:string,
    value:string
}

const KeysToComponentMap:any = {
    div: Div,
    fractionCircle:Div,    
    fractionRectangle:Div,
    button: Button,
    input: Input,
    table: Table,
    thead: Thead,
    tbody: Tbody,
    th: Th,
    tr: Tr,
    td: Td,
    img: Img,
    svg: Svg,
    circle: Circle,
    rectangle: Rectangle,
    cube: Cube,
    path:Path,
    polygon: Polygon,
    polyline: Polyline,
    ellipse: Ellipse,
    span: Span,
    br: Br,
    checkAnswer: CheckAnswer,
    lottie:Lottie,
    text:Text,
    svgG:SvgG,
    svgLine:SvgLine,
    svgMarker:SvgMarker,
    fragment: Fragment,
    tspan: TSpan
};

export const stylesMap = (styles:IStyle[]) => {
    let mappedStyles:any= {};
    styles.forEach((style:IStyle) => {
        mappedStyles[style.name] = style.value;
    });
    return mappedStyles;
};

export const isTV=()=>{
    let regexp = /tv|jiopages/i;
    return regexp.test(navigator.userAgent.toLowerCase());
} 

export const isMobileDevice=()=>{
    let regexp = /android|iphone|kindle|ipad/i;
    return regexp.test(navigator.userAgent.toLowerCase());
} 

export function renderer(config:any) {
    if (typeof KeysToComponentMap[config.component] !== "undefined") {
        return React.createElement(
            KeysToComponentMap[config.component], {
                id: config.id,
                key: config.id,
                text: config.text ? config.text : null,
                style: config.styles ? stylesMap(config.styles) : null,
                size: config.size ? config.size : null,
                type: config.type ? config.type : null,
                colspan: config.colspan ? config.colspan : null,
                onChange: config.onChange ? config.onChange : null,
                onKeyDown: config.onKeyDown ? config.onKeyDown : null,
                className: config.class ? config.class : null,
                draggable: config.draggable ? config.draggable : null,
                src: config.src ? config.src : null,
                cx: config.cx ? config.cx : null,
                cy: config.cx ? config.cy : null,
                radius: config.radius ? config.radius : null,
                height: config.height ? config.height : null,
                width: config.width ? config.width : null,
                fill: config.fill ? config.fill : null,
                stroke_width: config.stroke_width ? config.stroke_width : null,
                stroke: config.stroke ? config.stroke : null,
                points: config.points ? config.points : null,
                rx: config.rx ? config.rx : null,
                ry: config.ry ? config.ry : null,
                clickedVal: config.clickedVal ? config.clickedVal : null,
                actualVal: config.actualVal ? config.actualVal : null,
                clickable: config.clickable ? config.clickable : null,
                viewBox: config.viewBox ? config.viewBox : null,
                extraProps: config.extraProps ? config.extraProps : null,
            },
            config.children &&
            ((typeof config.children === "string" || typeof config.children === "number") ?
                config.children :
                config.children.map((c:any) => renderer(c)))
        );
    }
}

export default stylesMap;