import React, { useRef, useEffect } from 'react';
import countdownSound from './countdown.mp3'
import timerSound from './timer.mp3'
import correctSound from './correct.mp3'
import wrongSound from './wrong.mp3'
    
const MentalMathSound = (props:{type:string}) => {
    // the audio variable needs to be stored in a ref in order to access it across renders
    let audio:any = useRef();
    // start the audio (using the .current property of the ref we just created) when the component mounts using the useEffect hook
    useEffect(() => {
        switch (props.type.split("_")[0]) {
            case 'countdownSound':
                audio.current = new Audio(countdownSound);
                break;
            case 'timerSound':
                audio.current = new Audio(timerSound);
                break;
            case 'correctSound':
                audio.current = new Audio(correctSound);
                audio.current.volume = 40 / 100;
                break;
            case 'wrongSound':
                audio.current = new Audio(wrongSound);
                audio.current.volume = 40 / 100;
                break;
            default:
                break;
        }
        audio.current.play()
    }, [props.type])
    // Stop the audio when the component unmounts
    // (not exactly what you asked re React Router, but similar idea)
    useEffect(() => {
        return () => {
            audio.current.pause()
            console.log("in cleanup")
        }
    }, [props.type])

    return (<></>)
}
export default MentalMathSound;