import { AppContext } from "../../context/context";
import { decryptData, isTokenValid } from '../../utils/utils';
import { Dispatch, useContext, useEffect, useState } from "react";
import { StatusCodes } from "http-status-codes";
import apis from "../../apis/api";
import { ls } from "../../localStorage";
import { useLocation, useNavigate } from "react-router-dom";
import AdaptivePracticeForGrade1To5Students from "./adaptivePracticeForGrade1To5Students";
import AdaptivePracticeForTeacher from "./adaptivePracticeForTeachers";
import { useSelector } from "react-redux";
import { IReducer, IUserReducer } from "../../interface/interface";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { userAction } from "../../reduxStore";
import { Player } from "@lottiefiles/react-lottie-player";

interface IAPcurrentUser {
    id: string,
    classroom_id?: string,
    isB2C: boolean,
    curriculum_id?: string,
    unit_plan_id?: string,
    grade: number,
    role: string,
    dbToken: string,
    total_lo: number,
    token: string
}

export function AdaptivePractice(props: any) {
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const QUERY: { currentUser: IAPcurrentUser } | any = queryParams.get("query");
    const skillId: string | any = queryParams.get("skillId");
    const lang: string | any = queryParams.get("lang");
    const loginToken: string | any = queryParams.get("token");
    const state: any = useContext(AppContext);
    const [currentUser, setCurrentUser]: IAPcurrentUser | any = useState({});
    const [isLoader, setIsLoader] = useState(false);

    const userReducer: IUserReducer = useSelector((state: IReducer) => state.userReducer);

    useEffect(() => {
        verifyAndSetData();
    }, [])

    const verifyAndSetData = async () => {
        try {
            // console.log(queryParams);

            let currentUser: IAPcurrentUser | any = {};
            setIsLoader(true);
            if (QUERY) {
                // console.log(QUERY);
                let query: { currentUser: IAPcurrentUser } = decryptData(QUERY);
                currentUser = query.currentUser;
                dispatch(userAction.setCurrentUser(currentUser));
                // console.log(currentUser);
            }
            else {
                // call api to get token based on the login token
                let decode_token: { userId: string } = jwt_decode(loginToken);
                // console.log(decode_token)

                if (userReducer.currentUser.id &&
                    decode_token.userId.toString() === userReducer.currentUser.id.toString()) {
                    currentUser = userReducer.currentUser;
                    let currentToken = ls.getToken();
                    if (!isTokenValid(currentToken)) {
                        let res = await apis._refreshToken();
                        if (res.status === StatusCodes.OK)
                            currentToken = res.data.token
                    }
                    currentUser.token = currentToken;
                }
                else {
                    dispatch(userAction.setInitialState());
                    const init = await apis._initializeSession(loginToken);
                    console.log(init)
                    if (init.status === StatusCodes.OK) {
                        currentUser = init.data.collection
                        ls.setToken(currentUser.token);
                        ls.setDBToken(currentUser.dbToken);

                        dispatch(userAction.setCurrentUser(currentUser));
                    }
                }
            }

            // console.log(currentUser);
            // console.log(isTokenValid(currentUser.token));
            if (await ls.isValid(currentUser) &&
                await isTokenValid(currentUser.token)) {
                // console.log("setLS")
                ls.setToken(currentUser.token);
                ls.setDBToken(currentUser.dbToken);
                setCurrentUser(currentUser);
            }
            else {
                console.log("error while setting local storage");
                state.messageForParent('close');
                navigate('/pageNotFound');
            }
            setIsLoader(false);
        } catch (error) {
            console.log(error);
            state.messageForParent('close');
            navigate('/pageNotFound');
        }
    }

    return (<>
        {(isLoader) && (
            <div className='preloader d-flex align-items-center justify-content-center'>
                <Player id={"loaderAnimation"} autoplay loop style={{ "width": "40%", "height": "30%" }} speed={1} src={"./assets/animation/themes/space/loader.json"}></Player>
            </div>
        )}
        {(currentUser.role === 'student' && skillId !== "" && lang !== "") && (
            <AdaptivePracticeForGrade1To5Students {...props} currentUser={currentUser} skillId={skillId} lang={lang} />
        )}
        {(currentUser.role === 'teacher' && skillId !== "" && lang !== "") && (
            <AdaptivePracticeForTeacher {...props} currentUser={currentUser} skillId={skillId} lang={lang} />
        )}
    </>)
}