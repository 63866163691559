import { createContext } from "react";
import { IAdaptivePracticeContext, IInfo } from "../features/adaptivePractice/interface/interface";
export const AppContext = createContext({ windowHeight: window.innerHeight, windowWidth: window.innerWidth, messageForParent: (type: string, data: any) => { }, navigateTo: (path: string) => { } });


export const AdaptivePracticeContext = createContext<IAdaptivePracticeContext>({
    notificationMessage: '',
    streaks: 0,
    coins: 0,
    meter: { percentage: 0, level: '' },
    isLoader: false,
    isCoinAnimation: false,
    isShowStartPopup: false,
    isShowAdaptivePopup: false,
    isCloseFromClick: false,
    engineObj: {},
    loadContent: () => { },
    saveQuestionResponse: (answerObj: { givenAnswer: any, actualAnswer: any, isCorrectAns: Boolean }, clickCount: number) => { },
    getPopUpInfo: () => { return { totalAttemptedQuestions: 0, accuracy: 0, heading: '', showAnimation: false, streaks: 0, completedStatusPercentage: 0, isShowStartPopup: false, isCloseFromClick: false } },
    setShowAdaptivePopup: (isCloseFromClick?: boolean) => { },
    setShowStartPopup: (status: boolean) => { },
    onclose: () => { },
});
export const AdaptiveChallengeContext = createContext({ isChallenge: false, questions: [], date: '', attempted: 0, total: 0, correct: 0 });
export const renderedContext: any = createContext({ explanation: {}, hint: {}, saveQuestionResponse: () => { }, onFinish: () => { }, loadContent: () => { } });
