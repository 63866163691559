
import { useContext, useEffect } from "react";
import { AppContext } from "./context/context";

export function PageNotFound() {
    useEffect(() => {
        onPageNotFound();
    }, [])
    const state: any = useContext(AppContext);

    const onPageNotFound=()=>{
        state.messageForParent('close');
        window.location.replace(`${process.env.REACT_APP_MATHBUDDY_URL}/page-not-found.html`);
    }
    return (<></>)
}