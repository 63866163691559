// import '../adaptivePractice.css';
import { renderedContext } from '../context/context';
import { useState, useContext } from "react";
import { ReactEvent } from "@mathbuddy/adaptive-practice-events";
import { stylesMap, renderer, IConfig } from "./Render";
import components from "../assets/data/components.json";

export const CheckAnswerMentalMath = ({ className, id, style, children, clickedVal, actualVal, clickable, extraProps }: IConfig) => {

    // console.log(extraProps);
    const state: any = useContext(renderedContext);
    // console.log(state);
    let exp = state.explanation;
    // const adaptivePracticeReducer: IAdaptivePracticeReducer = useSelector((state: IReducer) => state.adaptivePracticeReducer);
    let [clickCount, setClickCount] = useState(0);
    const [showExplanation, setShowExplanation] = useState(false);
    const [disabled, setDisabled] = useState(state.isReview ? true : false);
    const [answerObj, setAnswerObj]: any = useState(state.isReview ? state.answerObj : {});

    const onClick = (id: string, clickedVal: any, actualVal: any, extraProps: any) => {
        setDisabled(true);
        extraProps = { ...extraProps, clickCount }
        let answerObj = new ReactEvent({ id, clickedVal, actualVal, extraProps }).handleEvent(extraProps.event);
        setAnswerObj(answerObj);
        let count = clickCount + 1;
        setClickCount(count);
        state.saveQuestionResponse(answerObj, count);
    }

    return (<>
        {/* Choices */}
        {(state.isReview) && (
            <button className="showExplanation" onClick={() => { setShowExplanation(true) }}>Show explanation</button>
        )}
        {(extraProps.type === "choices") && (
            <div id="mental-math-choices">
                <div id={id} className={className} style={style}>
                    {extraProps.children.map((child: any, ii: number) => {
                        return (
                            <button key={ii} id={child.id} className={child.class} style={stylesMap(child.styles)}
                                disabled={disabled} onClick={() => { onClick(child.id, child.clickedVal, child.actualVal, child.extraProps) }}>
                                {child.children.map((val: any) => {
                                    if (state.isMentalMath) {
                                        let correctStyle = { name: 'backgroundColor', value: 'var(--secondary-green)' }
                                        let wrongStyle = { name: 'backgroundColor', value: 'var(--secondary-red)' }
                                        if (answerObj.givenAnswer === ii) {
                                            let bgStyle = answerObj.isCorrect ? correctStyle : wrongStyle;
                                            val.styles = [...val.styles, bgStyle];
                                        }
                                        if (answerObj.actualAnswer === ii) {
                                            val.styles = [...val.styles, correctStyle];
                                        }
                                    }
                                    let objRendered;
                                    objRendered = renderer(val);
                                    return objRendered;
                                })}
                            </button>
                        )
                    })}

                </div>
            </div>
        )}

        {/* Explanation */}
        {(showExplanation) && (
            <div id="mental-math-explanation" className="d-flex flex-column align-items-start justify-content-start"
                onTouchMove={() => { setShowExplanation(false) }} onDrag={() => { setShowExplanation(false) }}>
                <button id="downBtn" onTouchStart={() => { setShowExplanation(false) }} onDrag={() => { setShowExplanation(false) }} onClick={() => { setShowExplanation(false) }}>
                    <img src={components.baseURL + 'down-btn.svg'} alt=""></img>
                </button>
                {
                    exp.children.map((val: any) => {
                        let objRendered;
                        // console.log(state.outputJSON.explanation);
                        objRendered = renderer(val);
                        return objRendered;
                    })
                }
            </div>
        )}
    </>)

}