import { Dispatch } from 'redux';
class UserActions {

    setInitialState = () => {
        return (dispatch: Dispatch<any>) => {
            return new Promise<void>((resolve, reject) => {
                dispatch({
                    type: 'SET_INITIAL_STATE',
                    payload: {}
                })
                resolve()
            });
        }
    }

    setCurrentUser = (data: any) => {
        return {
            type: 'SET_CURRENT_USER',
            payload: data,
        }
    }

}

export const userAction = new UserActions();