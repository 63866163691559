import { useEffect, useRef, useState } from "react";
import "./mentalQuiz.css";
import components from "../../assets/data/components.json";
import correctAudio from './sound/correct.mp3';
import wrongAudio from './sound/wrong.mp3';
import countDown from './sound/countdown.mp3';
import { BackButton, CloseButton, MainButton } from "../../components/atoms/button/Button";
import { MainHeader } from "../../components/atoms/header/Header";
import { renderer } from "../../renderer/Render";
import { renderedContext } from "../../context/context";
import { getTimeSpent } from "../../utils/utils";
import { ICurrentUser } from "../../interface/interface";
import MentalMathSound from "./sound/MentalMathSound";
import { FAHeart, FAHeartBroken, FAStar } from "../../components/atoms/fontawesomeIcon/Fontawesome";
import AppLoader, { ConfettiAnimation } from "../../components/atoms/animation/Animation";
import apis from "../../apis/api";

interface ChildProps {
  onTryAgain: () => void;
  onClose: () => void;
  msgToParent: (type: string, data: any) => void;
  questions: { component: { questionText: any, explanation: any, choicesComponent: any }, answerObj?: {} }[],
  loId: string,
  currentLevel: { id: string; level: number; order: number; stars: number; }
  currentUser: ICurrentUser
  currentStreak: number;
}

interface ColorCodes {
  info: { color: string },
  warning: { color: string, threshold: number },
  alert: { color: string, threshold: number },
}

const COLOR_CODES: ColorCodes = {
  info: { color: "green" },
  warning: { color: "orange", threshold: 40 },
  alert: { color: "red", threshold: 20 }
};

const TIME_LIMIT = 64;

export const MentalQuiz: React.FC<ChildProps> = ({
  onTryAgain, onClose, msgToParent,
  questions, loId, currentLevel, currentUser, currentStreak }) => {

  const audio = new Audio(correctAudio);
  const w_audio = new Audio(wrongAudio);
  const [loader, setLoader] = useState(false);
  const [timerSound, setTimerSound] = useState({ isPlay: false, type: '' });
  const [countDownSound, setCountDownSound] = useState({ isPlay: false, type: '' });
  const [count, setCount] = useState(0);
  const [currentQuestion, setCurrentQuestion]: any = useState({});
  const [startTime, setStartTime]: any = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [pathD, setPathD] = useState("");
  const [view, setView] = useState("");
  const [FULL_DASH_ARRAY, setFullDashArray] = useState(0);
  const [timeLeft, setTimeLeft] = useState(TIME_LIMIT);
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout | null>(
    null
  );
  const [remainingPathColor, setRemainingPathColor] = useState(
    COLOR_CODES.info.color
  );
  const [timer, setTimer] = useState(3);
  const [questionResponse, setQuestionResponse]: any = useState([]);
  const questionResponseRef: any = useRef(); // <-- ref to cache questionResponse value
  const [incorrect, setIncorrect]: any = useState(0);
  const [life, setLife]: any = useState(3);
  const [stars, setStars]: any = useState(3);
  const starsRef: any = useRef(); // <-- ref to cache stars value
  const [streak, setStreak]: any = useState(currentStreak);
  const [coins, setCoins]: any = useState(0);
  const [isMentalMathCompleted, setIsMentalMathCompleted] = useState(false);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [closeMentalMath, setCloseMentalMath] = useState(false);
  const [closeAfterSave, setCloseAfterSave] = useState(false);

  // update questionResponseRef on length change
  useEffect(() => {
    questionResponseRef.current = questionResponse;
  }, [questionResponse.length])

  // update starsRef on stars change
  useEffect(() => {
    starsRef.current = stars;
  }, [stars])

  useEffect(() => {
    // handle window resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Play count down music after 1 second for let say 3 2 1.
    setTimeout(() => {
      setCountDownSound({ isPlay: true, type: 'countdownSound' });
    }, 1000);

    // Start Calculating time each second how much time is remaining.
    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    setTimerInterval(interval);

    // set mentalMath questions
    setCurrentQuestion(questions[0]);

    // Clear on unmounting;
    return () => {
      console.log(questionResponse)
      console.log(questionResponseRef.current)
      console.log(starsRef.current);
      if (questionResponseRef.current.length > 0)
        msgToParent('save', {
          questions: questionResponseRef.current,
          checkBadge: (currentLevel.stars === 0 && starsRef.current > 0) ? true : false
        });
      clearTimerInterval()
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    if (timer === 3) {
      setTimeout(() => {
        if (timer > 0) {
          const countdown = setInterval(() => {
            if (timer < -60)
              clearInterval(countdown);
            setTimer((prevTimer) => prevTimer - 1);
          }, 1000);
          return () => {
            clearInterval(countdown);
            setTimer(0);
          }
        }
      }, 1000);
    }
    if (timer === 0) {
      setTimerSound({ isPlay: true, type: 'timerSound' })
      setStartTime(new Date());
      // timer_audio.current.play();
    }
  }, [timer]);

  useEffect(() => {
    // console.log("timeLeft....")
    // console.log(timeLeft)
    if (timeLeft <= 0) {
      // console.log("times up")
      clearTimerInterval();
      setLife(0);
      onCompleteMentalMath();
    } else {
      setRemainingPathColor(() => {
        const { info, alert, warning } = COLOR_CODES;
        return (timeLeft <= alert.threshold!) ? alert.color
          : (timeLeft <= warning.threshold!) ? warning.color
            : info.color;
      });
    }
  }, [timeLeft]);

  useEffect(() => {
    const VBH = windowHeight * 0.5;
    const VBW = windowWidth * 0.9;
    const startY = VBH * 0.98;
    const endY = VBH * 0.02;
    const startX = VBW * 0.02;
    const endX = VBW * 0.98

    const path = `M ${VBW * 0.50} ${startY} 
                  L ${VBW * 0.10} ${startY} 
                  Q ${startX} ${startY} ${startX} ${VBH * 0.75} 
                  L ${startX} ${VBH * 0.25} 
                  Q ${startX} ${endY} ${VBW * 0.10} ${endY} 
                  L ${VBW * 0.90} ${endY}  
                  Q ${endX} ${endY} ${endX} ${VBH * 0.25} 
                  L ${endX} ${VBH * 0.75} 
                  Q ${endX} ${startY} ${VBW * 0.90} ${startY} 
                  L ${VBW * 0.50} ${startY}`

    const view = `0 0 ${VBW} ${VBH}`;
    setView(view);
    setPathD(path);
    setFullDashArray((VBH * 2) + (VBW * 2))
  }, [windowHeight])


  const clearTimerInterval = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
    }
  };

  const formatTime = (seconds: number) => {
    return `00:${(seconds < 10 ? '0' : '') + seconds}`;
  };

  const getCircleDasharray = () => {
    const circleDasharray = `${(FULL_DASH_ARRAY / 66) * timeLeft} ${FULL_DASH_ARRAY}`;
    return circleDasharray;
  };
  let component = currentQuestion.component;

  const saveQuestionResponse = async (answerObj: { givenAnswer: any, actualAnswer: any, isCorrectAns: Boolean }, clickCount: number) => {
    // console.log(answerObj, clickCount);
    questions[count].answerObj = answerObj;
    // console.log(questions);
    let questionResponseArr = [
      ...questionResponse,
      {
        "isNewSession": (questionResponse.length === 0) ? true : false,
        "user_id": currentUser.id,
        "classroom_id": currentUser.classroom_id,
        "lo_id": loId,
        "question_id": currentLevel.id,
        "review_obj": currentQuestion.reviewObj,
        "lang": currentQuestion.langObj.lang,
        "number_lang": currentQuestion.langObj.number_lang,
        "answers": [{ "isCorrect": answerObj.isCorrectAns, "answer": answerObj.givenAnswer }],
        "isCorrect": answerObj.isCorrectAns,
        "blooms": currentQuestion.blooms,
        "time_spent": getTimeSpent(startTime),
      }]
    let loadNextQuestion = true;
    setQuestionResponse(questionResponseArr)
    if (!answerObj.isCorrectAns) {
      w_audio.play();
      setStreak(0);
      setLife((prevLife: number) => prevLife - 1);
      setStars((prevStars: number) => prevStars - 1);

      let incorrectCount = incorrect + 1;
      setIncorrect(incorrectCount);

      if ((currentLevel.stars === 0 && incorrectCount === 3)
        || (currentLevel.stars > 0 && incorrectCount === 2)) {
        // setTimeout(() => {
        loadNextQuestion = false;
        setShowTryAgain(true);
        onCompleteMentalMath()
        // }, 1000)
      }
    }
    else {
      setStreak((prevStreak: number) => prevStreak + 1);
      setCoins((prevCoins: number) => prevCoins + 1);
      audio.play();
    }

    if (loadNextQuestion)
      setTimeout(() => {
        if (count < questions.length - 1) {
          let incCount = count + 1;
          setCount(incCount);
          setStartTime(new Date());
          setCurrentQuestion(questions[incCount]);
        }
        else
          onCompleteMentalMath();
      }, 500)

  }

  const onCompleteMentalMath = (timer = 1000) => {
    if (questionResponse.length < 10)
      setStars(currentLevel.stars);

    setTimeout(() => {
      setIsMentalMathCompleted(true);
    }, timer)
  }

  useEffect(() => {
    if (isMentalMathCompleted) {
      setTimerSound({ isPlay: false, type: '' })
      saveMentalMathQuestions();
    }

  }, [isMentalMathCompleted])

  const saveMentalMathQuestions = async () => {
    if (questionResponse.length >= 10 && stars > 0) {
      setShowAnimation(true)
      setTimeout(() => {
        setShowAnimation(false);
      }, 2000);
    }

    if (questionResponse.length > 0) {
      let saveMetalMathObj = {
        questions: questionResponse,
        stars: stars
      };
      // console.log(saveMetalMathObj);
      setLoader(true);
      await apis._saveMentalMathQuestions(saveMetalMathObj);
      setLoader(false);
    }

    if (closeAfterSave)
      onClose()
  }

  const saveAndClose = () => {
    setCloseAfterSave(true);
    onCompleteMentalMath(0);
  }

  useEffect(() => {
    if (closeMentalMath) {
      onClose();
    }
  }, [closeMentalMath])

  const correct = questionResponse.filter((q: { isCorrect: boolean }) => q.isCorrect).length
  const total = questionResponse.length;

  return (
    <>
      {loader && <AppLoader></AppLoader>}
      {(timer > 0) && (
        <div id="timer_outer_div">
          <CloseButton onClick={() => { setCloseMentalMath(true) }} height="5vh" top='1%' right='1%'></CloseButton>
          <div className="timer_div">
            <h1 className="timer_text">{timer}</h1>
          </div>
        </div>
      )}
      {(countDownSound.isPlay) && (<MentalMathSound type={countDownSound.type}></MentalMathSound>)}
      {(timer < 1 && !isMentalMathCompleted && !isReview) && (
        <div id="mental-math-main-container">
          {(timerSound.isPlay) && (<MentalMathSound type={timerSound.type}></MentalMathSound>)}
          <CloseButton onClick={saveAndClose} height="5vh" top='1%' right='1%'></CloseButton>
          <div style={{ height: '10vh' }} className="d-flex align-items-end justify-content-around w-100">
            <div id="mental-math-streak">
              <img alt="" src={components.baseURL + 'fire.svg'}></img>
              <span>{streak}</span>
            </div>
            <div id="mental-math-head" className="d-flex flex-column"> <span>Question {count + 1}</span>
              <div id="mental-math-life" className="d-flex align-items-center justify-content-center">
                {([1, 2, 3].map((n: number) => {
                  return (currentLevel.stars > 0 && n == 1) ? (<></>)
                    : life >= n ? (<FAHeart key={n} color={"red"}></FAHeart>)
                      : (<FAHeartBroken key={n}></FAHeartBroken>)
                }))}
              </div>
            </div>
            <div id="mental-math-coin">
              <img alt="" src={components.baseURL + 'coin.svg'}></img>
              <span>{coins}</span>
            </div>
          </div>
          <renderedContext.Provider value={{
            explanation: component.explanation,
            hint: component.hint, challenge: {}, type: 'mental-math',
            saveQuestionResponse: saveQuestionResponse
          }}>
            <div className="mental-math-question-content d-flex flex-column align-items-center justify-content-start">
              <svg className="base-timer__svg" viewBox={view}>
                <g className="base-timer__circle">
                  <path id="base-timer-path-remaining"
                    strokeDasharray={getCircleDasharray()}
                    className={`base-timer__path-remaining ${remainingPathColor}`}
                    d={pathD}
                  ></path>
                </g>
              </svg>
              <div id="question">
                {(Object.keys(currentQuestion.component).map((val) => {
                  if (['questionText', 'picInfoComponent', 'tableComponent'].indexOf(val) > -1) {
                    let objRendered;
                    objRendered = renderer(currentQuestion.component[val]);
                    return objRendered;
                  }
                })
                )}
              </div>
              <span id="base-timer-label" className="base-timer__label">
                {formatTime(timeLeft)}
              </span>
            </div>

            <div id="mental-math-choices">
              {(Object.keys(currentQuestion.component).map((val) => {
                if (['choicesComponent'].indexOf(val) > -1) {
                  let objRendered;
                  objRendered = renderer(currentQuestion.component[val]);
                  return objRendered;
                }
              })
              )}
            </div>
          </renderedContext.Provider>
        </div>
      )}
      {(!loader && showTryAgain && isMentalMathCompleted) && (
        <div id="mental-math-main-container" className="d-flex flex-column align-items-center justify-content-center">
          <div className="tryAgainContainer d-flex flex-column align-items-center justify-content-evenly">
            <CloseButton onClick={() => { setCloseMentalMath(true) }} height="5vh" top='1%' right='1%'></CloseButton>
            <img alt="" id="purpleCharacter" src={components.baseURL + 'purpleCharacter.svg'}></img>
            <div>Oh Oh! You got {incorrect} incorrect</div>
            <div className="tryAgainBtn">
              <MainButton text="Try Again" variant={2} active={true} height={'100%'} width={"100%"} onClick={onTryAgain}></MainButton>
            </div>
          </div>
        </div>
      )}
      {(!loader && !showTryAgain && isMentalMathCompleted && !isReview) && (
        <div id="mentalMathInfoPage" className="mentalMath d-flex flex-column align-items-center justify-content-start">
          <BackButton onClick={() => { setCloseMentalMath(true) }} ></BackButton>
          <MainHeader text="Result"></MainHeader>
          <div className="mentalMathLevelList d-flex flex-column align-items-center">
            <div className='reviewCard d-flex flex-column align-items-center justify-content-evenly'>
              <img alt="" id="vector_741_1" src={components.baseURL + 'vector-741.svg'}></img>
              <img alt="" id="vector_1021_2" src={components.baseURL + 'vector-1021.svg'}></img>
              <img alt="" id="union_100_3" src={components.baseURL + 'union.svg'}></img>
              <img alt="" id="ellipse_107_4" src={components.baseURL + 'ellipse-107.svg'}></img>
              <img alt="" id="ellipse_109_5" src={components.baseURL + 'ellipse-109.svg'}></img>
              <img alt="" id="vector_100_16" src={components.baseURL + 'vector-100.svg'}></img>
              <img alt="" id="vector_1061_7" src={components.baseURL + 'vector-1061.svg'}></img>
              <img alt="" id="vector_741_8" src={components.baseURL + 'vector-741.svg'}></img>
              <img alt="" id="vector_741_9" src={components.baseURL + 'vector-741.svg'}></img>
              <img alt="" id="vector_741_10" src={components.baseURL + 'vector-741.svg'}></img>
              <img alt="" id="vector_741_11" src={components.baseURL + 'vector-741.svg'}></img>
              <img alt="" id="vector_1021_12" src={components.baseURL + 'vector-1021.svg'}></img>
              <img alt="" id="vector-741" src={components.baseURL + 'vector-741.svg'}></img>
              <img alt="" id="vector_1021_15" src={components.baseURL + 'vector-1021.svg'}></img>
              <img alt="" id="vector_1021_16" src={components.baseURL + 'vector-1021.svg'}></img>
              <div className='message'>{(total > 9) ? (stars > 2 ? 'Congratulations!' : 'Can do better!')
                : 'Improve your speed!'}</div>
              {(currentLevel.stars === 0) && (
                <div className='toDoToCompleteLevel'>{(total > 9) ? (stars > 0 ? 'You have finished this level' : 'Try Again to finished this level')
                  : 'You need to answer atleast 10 questions to finish this level'}</div>
              )}
              {(total > 9) && (
                <>
                  <div>
                    {([1, 2, 3].map((n: number) => {
                      if (stars >= n)
                        return (
                          <FAStar key={n} color="orange"></FAStar>
                        )
                    }))}
                  </div>
                  {(showAnimation) && (
                    <ConfettiAnimation></ConfettiAnimation>
                  )}
                </>
              )}
            </div>
            <div className='coinsCollection d-flex flex-column align-items-center justify-content-center'>
              <div>Coins collected</div>
              <div className="coins">{correct}</div>
            </div>
            <div className='reviewBtnContainer d-flex flex-wrap align-items-center justify-content-center'>
              {questions.map((q: any, ii: number) => {
                if (q.answerObj)
                  return (<div key={ii} className={`reviewBtn ${(q.answerObj.isCorrectAns) ? 'correct' : 'wrong'} d-flex align-items-center justify-content-center`}
                    onClick={() => { setCurrentQuestion(questions[ii]); setCount(ii); setIsReview(true) }}>
                    {ii + 1}
                  </div>)
              })}
            </div>
          </div>
        </div>
      )}
      {(!showTryAgain && isMentalMathCompleted && isReview) && (
        <div id="mental-math-main-container">
          <CloseButton onClick={() => { setIsReview(false) }} height="5vh" top='1%' right='1%'></CloseButton>
          <div id="mental-math-head"> <h2>Question {count + 1}</h2></div>
          <renderedContext.Provider value={{
            type: 'mental-math',
            isReview: true,
            explanation: component.explanation,
            answerObj: currentQuestion.answerObj,
            hint: component.hint,
            saveQuestionResponse: () => { }
          }}>
            <div className="mental-math-question-content d-flex flex-column align-items-center justify-content-start">
              <svg className="base-timer__svg" viewBox={view}>
                <g className="base-timer__circle">
                  <path id="base-timer-path-remaining"
                    style={{ strokeWidth: "0px" }}
                    className={`base-timer__path-remaining ${'white'}`}
                    d={pathD}
                  ></path>
                </g>
              </svg>
              <div id="question">
                {(Object.keys(currentQuestion.component).map((val) => {
                  if (['questionText', 'picInfoComponent','tableComponent'].indexOf(val) > -1) {
                    let objRendered;
                    objRendered = renderer(currentQuestion.component[val]);
                    return objRendered;
                  }
                })
                )}
              </div>
            </div>


            {(Object.keys(currentQuestion.component).map((val) => {
              if (['choicesComponent'].indexOf(val) > -1) {
                let objRendered;
                objRendered = renderer(currentQuestion.component[val]);
                return objRendered;
              }
            })
            )}
          </renderedContext.Provider>
        </div>
      )}
    </>
  );
};

export default MentalQuiz;
