import { AdaptivePracticeContext, AppContext } from "../../context/context";
import { SpaceFrame } from "./frame/space";
import { ContentFactoryImpl, Content } from '@mathbuddy/adaptive-practice-engine';
import { getTimeSpent } from '../../utils/utils';
import { Component } from "react";
import { StatusCodes } from "http-status-codes";
import apis from "../../apis/api";
import { adaptivePracticeActions } from "../../reduxStore";
import { connect } from "react-redux";


class AdaptivePracticeForTeacher extends Component<any, any> {
    static contextType = AppContext;
    context!: { windowHeight: string, windowWidth: string, messageForParent: any, navigateTo: any };
    constructor(props: any) {
        super(props)
        this.state = {
            currentUser: this.props.currentUser,
            skillId: this.props.skillId,
            langObj: { number_lang: '', lang: '' },
            contentFactoryObj: new ContentFactoryImpl(),
            contentObj: new Content(),
            isNewSession: true,
            isLoader: false,
            isCoinAnimation: false,
            coins: 0,
            streaks: 0,
            currentQuestionId: '',
            currentQuestionPosition: 0,
            totalQuestions: 0,
            uniqQuestionIds: [],
            startTime: 0,
            totalCorrectAttemptedQuestion: 0,
            totalAttemptedQuestions: 0,
            totalAttemptedCount: 0,
            accuracy: 0,
            notificationMessage: '',
            response: { component: [] },
            isShowAdaptivePopup: false,
            isCloseFromClick: false,
        }
    }

    componentDidMount = () => {
        this.getLatestStateOnMount();
    }


    getLatestStateOnMount = async () => {
        await this.sendLastAttemptedIfAny(); // if window is reloaded and last attempted questions is there send it to DB.
        this.setState({ isLoader: true });
        // let skillStatus = await this.getSkillStatus(); 
        let streaksAndCoins = await this.getStreaksAndCoins();
        let currentUser = this.props.currentUser;
        let country_code = currentUser.classroom_country_code;

        let langObj = { number_lang: 'en', lang: this.props.lang + '_' + country_code.toLowerCase() };
        this.setState({ ...streaksAndCoins, langObj, isLoader: false }, () => {
            this.loadSkillContent();
        })
    }

    // getSkillStatus = async () => {
    //     let returnObj = {
    //         totalAttemptedCount: 0,
    //         totalCorrectAttemptedQuestion: 0
    //     }

    //     let skillStatusData = {
    //         "filterList": [
    //             {
    //                 "columnName": "user_id",
    //                 "type": "filterId",
    //                 "value": [this.state.currentUser.id]
    //             },
    //             {
    //                 "columnName": "lo_id",
    //                 "type": "filterId",
    //                 "value": [this.state.skillId]
    //             }
    //         ],
    //         "sortHeader": "createdAt",
    //         "sortDirection": "DESC",
    //         "page": 1,
    //         "limit": 1,
    //         "isPagination": true
    //     }

    //     if (this.state.currentUser.classroom_id) {
    //         skillStatusData.filterList.push({
    //             "columnName": "classroom_id",
    //             "type": "filterId",
    //             "value": [this.state.currentUser.classroom_id]
    //         });
    //     }

    //     let skillStatusRes = await apis._getSkillStatus(skillStatusData);
    //     //console.log(skillStatusRes);
    //     if (skillStatusRes?.data?.code === StatusCodes.OK) {
    //         if (skillStatusRes.data.result.data.length > 0) {
    //             returnObj.totalAttemptedCount = skillStatusRes.data.result.data[0].questions
    //             returnObj.totalCorrectAttemptedQuestion = skillStatusRes.data.result.data[0].correct_answers
    //         }
    //     }

    //     return returnObj;
    // }

    getStreaksAndCoins = async () => {
        let returnObj = { coins: 0, streaks: 0 };

        let currentUser = this.state.currentUser;
        let dashboardSummary = await apis._getLastUserSummary(currentUser.id);
        if (dashboardSummary.status === StatusCodes.OK && dashboardSummary.data.result.length > 0) {
            let lastSummary = dashboardSummary.data.result[0];

            returnObj.coins = lastSummary.total_coins
            returnObj.streaks = lastSummary.current_streak;
        }

        return returnObj;
    }

    loadSkillContent = async () => {
        try {
            this.sendLastAttemptedIfAny();// if Teacher reload the questions or click next or prev and last attempted is not send to db incase of wong attempt in first attempt 
            this.setState({ isLoader: true, response: { component: [] } })
            let skillId = this.state.skillId;
            let currentQuestionPosition = this.state.currentQuestionPosition;
            let langObj = this.state.langObj;

            await this.state.contentObj.loadSkillBySkillId(skillId, false, langObj.lang)
            let totalQuestions = await this.state.contentObj.getNumberOfQuestions();

            let currentQuestionId: string = await this.state.contentObj.loadQuestionByNumber(currentQuestionPosition);

            let response = await this.state.contentFactoryObj.displayQuestion(this.state.contentObj,
                this.state.contentObj.createContentTemplateObject(currentQuestionId, 'REACT_WEB'), langObj);
            console.log("response_skills", response);

            // Update state
            this.setState({
                startTime: new Date(),
                currentQuestionId,
                response,
                totalQuestions
            }, () => {
                //console.log(this.state);
                this.setState({ isLoader: false });
            })

        } catch (error) {
            this.setState({ isLoader: false })
        }
    }

    getNotificationMessage = (streak: number) => {
        let message = ''
        if (streak > 0 && (streak % 2) === 0) {
            message = (streak < 10) ? `${streak} in a row!` :
                (streak < 20) ? `You are on a roll! ${streak} in a row` :
                    (streak <= 22) ? `Bravo! ${streak} in a row` :
                        `Way to go. ${streak} in a row`;
        }
        return message;
    }

    saveQuestionResponse = async (answerObj: { givenAnswer: any, actualAnswer: any, isCorrectAns: Boolean }, clickCount: number) => {
        let isCorrectAns = answerObj.isCorrectAns;

        let coins = (isCorrectAns) ? (this.state.coins + 1) : this.state.coins;
        let streaks = (isCorrectAns) ? (this.state.streaks + 1) : 0;
        let time_spent = getTimeSpent(this.state.startTime);
        let notificationMessage = this.getNotificationMessage(streaks);
        let totalCorrectAttemptedQuestion = (isCorrectAns) ? this.state.totalCorrectAttemptedQuestion + 1 : this.state.totalCorrectAttemptedQuestion;
        let totalAttemptedQuestions = (isCorrectAns || clickCount > 1) ? this.state.totalAttemptedQuestions + 1
            : this.state.totalAttemptedQuestions;

        let totalAttemptedCount = this.state.totalAttemptedCount + 1;
        let accuracy = Math.round((totalCorrectAttemptedQuestion * 100) / totalAttemptedCount) || 0;

        this.setState({
            isCoinAnimation: isCorrectAns, coins, streaks, notificationMessage, totalCorrectAttemptedQuestion,
            totalAttemptedQuestions, accuracy, totalAttemptedCount, isCloseFromClick: false
        },
            () => {
                setTimeout(() => {
                    this.setState({ isCoinAnimation: false, notificationMessage: '' })
                }, 1700)
            });

        let lastAttemptedData = this.props.adaptivePracticeReducer.lastAttemptedQuestion;
        let currentAnswer = [{ "isCorrect": answerObj.isCorrectAns, "answer": answerObj.givenAnswer }];

        let currentQuestionId = this.state.currentQuestionId;

        let data = {
            "questions": [{
                "isNewSession": this.state.isNewSession,
                "isReAttempt": false,
                "user_id": this.state.currentUser.id,
                "lo_id": this.state.skillId,
                "question_id": currentQuestionId,
                "review_obj": this.state.response.reviewObj,
                "number_lang": this.state.langObj.number_lang,
                "lang": this.state.langObj.lang,
                "answers": [...lastAttemptedData.questions[0].answers, ...currentAnswer],
                "isCorrect": answerObj.isCorrectAns,
                "isChallenge": false,
                "isChallengeCompleted": false,
                "blooms": this.state.response.blooms,
                "time_spent": lastAttemptedData.questions[0].time_spent + time_spent,
                "current_level": 'Mastered',
                "classroom_id": this.state.currentUser.classroom_id,
            }]
        }

        if (clickCount === 1 && !answerObj.isCorrectAns) {
            this.props.setAPLastAttempted(data);
            return    // return from here if answer is incorrect in the first attempt 
        }

        // console.log(data);
        let uniqQuestionIds: string[] = this.state.uniqQuestionIds;
        let filterUniqQuestionId = uniqQuestionIds.filter((qId: string) => qId.toString() === currentQuestionId.toString());
        if (filterUniqQuestionId.length === 0)
            uniqQuestionIds.push(currentQuestionId);

        this.setState({ isNewSession: false, uniqQuestionIds },
            async () => {
                let response = await this.saveQuestionResponseToDB(data);
                // console.log(response);
                if (response?.status === StatusCodes.OK) {
                    if (isCorrectAns)
                        this.loadSkillContent();
                }
            });
    }

    saveQuestionResponseToDB = async (data: any) => {
        // console.log(data);
        let response = await apis._saveQuestionResponse(data);
        if (response?.status === StatusCodes.OK) {
            this.props.setAPDefaultLastAttempted();// set default last attempted question with empty answer array.
        }
        return response;
    }

    getPopUpInfo = () => {
        let accuracy = this.state.accuracy;
        let questionCompleted = Math.round((this.state.uniqQuestionIds.length / this.state.totalQuestions) * 100);

        let heading =
            (questionCompleted <= 20) ? "You are just getting started! Are you sure?" :
                (questionCompleted <= 40) ? "There are more questions to go! Are you sure?" :
                    (questionCompleted <= 80) ? "You are doing well! Are you sure?" :
                        (questionCompleted < 100) ? "Just a few more questions to go! Are you sure?" :
                            (accuracy === 0) ? 'You need to practice a lot' :
                                (accuracy < 50) ? 'Can do better with practice' :
                                    (accuracy < 80) ? 'Keep practicing. You can do better' :
                                        (accuracy < 90) ? 'You did well. Try to score 100%' :
                                            'Way to go! You were perfect!';

        let showAnimation = (questionCompleted === 100) ? true : false;

        return {
            streaks: this.state.streaks,
            accuracy: accuracy,
            totalAttemptedQuestions: this.state.totalAttemptedQuestions,
            completedStatusPercentage: questionCompleted,
            isShowStartPopup: false,
            isCloseFromClick: this.state.isCloseFromClick,
            heading, showAnimation
        }
    }

    setShowAdaptivePopup = (isCloseFromClick: Boolean = false) => {
        let isShowAdaptivePopup = !this.state.isShowAdaptivePopup
        this.setState({ isCloseFromClick, isShowAdaptivePopup });
    }

    sendLastAttemptedIfAny = async () => {
        let lastAttemptedData = this.props.adaptivePracticeReducer.lastAttemptedQuestion;
        if (lastAttemptedData.questions[0].answers.length) {
            this.setState({ isLoader: true });
            await this.saveQuestionResponseToDB(lastAttemptedData);
            this.setState({ isLoader: false });
        }
    }

    onClose = async () => {
        await this.sendLastAttemptedIfAny();
        this.context.messageForParent('close');
    }

    render() {
        return (
            <AdaptivePracticeContext.Provider value={{
                notificationMessage: this.state.notificationMessage,
                coins: this.state.coins,
                streaks: this.state.streaks,
                meter: { percentage: 0, level: '' },
                isLoader: this.state.isLoader,
                isCoinAnimation: this.state.isCoinAnimation,
                isShowStartPopup: false,
                isShowAdaptivePopup: this.state.isShowAdaptivePopup,
                isCloseFromClick: this.state.isCloseFromClick,
                engineObj: this.state.response,
                options: {
                    isShowReload: true,
                    nextPrevBtn: {
                        current: this.state.currentQuestionPosition,
                        total: this.state.totalQuestions,
                        setCurrentPos: (pos: number) => {
                            this.setState({ currentQuestionPosition: pos }, () => {
                                this.loadSkillContent();
                            })
                        }
                    }
                },
                loadContent: this.loadSkillContent,
                saveQuestionResponse: this.saveQuestionResponse,
                getPopUpInfo: this.getPopUpInfo,
                setShowAdaptivePopup: this.setShowAdaptivePopup,
                onclose: this.onClose
            }}>
                <SpaceFrame></SpaceFrame>
            </AdaptivePracticeContext.Provider>
        )
    }
}

function mapStateToProps(state: any) {
    return state;
}

let setAPLastAttempted = adaptivePracticeActions.setAPLastAttempted;
let setAPDefaultLastAttempted = adaptivePracticeActions.setAPDefaultLastAttempted;


const mapDispatchToProps = { setAPLastAttempted, setAPDefaultLastAttempted };

export default connect(mapStateToProps, mapDispatchToProps)(AdaptivePracticeForTeacher);