import { IConfig } from "./Render";
export const SvgMarker = ({className, id, children, style, extraProps}:IConfig)=>
{   
    return (
           <marker id={id} className={className} style={style} 
           viewBox={extraProps.viewBox}
           refX={extraProps.refX} refY={extraProps.refY}
           markerUnits={extraProps.markerUnits}  markerWidth={extraProps.markerWidth}
           orient={extraProps.orient}
           markerHeight={extraProps.markerHeight}>{children}</marker>
    );
}