const initialState = {
  lastAttemptedQuestion: { questions: [{ answers: [], time_spent: 0 }] },
  challenge: { result: [], isResultSaved: false }
};

const adaptivePracticeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_INITIAL_STATE":
      return { ...initialState };
    case "SET_AP_DEFAULT_LAST_ATTEMPTED":
      return { ...state, lastAttemptedQuestion: { questions: [{ answers: [], time_spent: 0 }] } };
    case "SET_AP_LAST_ATTEMPTED":
      return { ...state, lastAttemptedQuestion: action.payload };
    case "SET_AP_IS_CHALLENGE_RESULT_SAVED":
      return { ...state, challenge: { ...state.challenge, isResultSaved: action.payload } };
    case "SET_AP_CHALLENGE_RESULT":
      return { ...state, challenge: { ...state.challenge, result: action.payload } };
    default:
      return state;
  }
}

export default adaptivePracticeReducer