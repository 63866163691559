import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LearnActivity } from './features/learn/LearnActivity';
import { PageNotFound } from './MBRoutes';
import MentalMath from "./features/mentalMath/MentalMath";
import { useEffect, useState } from "react";
import { AppContext } from './context/context';
import { AdaptivePractice } from "./features/adaptivePractice/adaptivePractice";
import AdaptiveDailyChallengeForGrade1To5Students from "./features/adaptivePractice/adaptiveChallengeForGrade1To5Students";

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const setWindowDimensions = () => {
    console.log("resize")
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    }
  }, [])

  const messageForParent = (type: string, data: any = {}) => {
    window.parent.postMessage(JSON.stringify({ type, data }), '*');
  }

  const navigateTo = (path: string) => {
    window.location.replace(path);
  }

  return (<AppContext.Provider value={{ windowHeight, windowWidth, messageForParent: messageForParent, navigateTo: navigateTo }}>
    <Router>
      <Routes>
        <Route path='/learnActivity' element={<LearnActivity></LearnActivity>}></Route>
        <Route path='/mentalMath' element={<MentalMath></MentalMath>}></Route>
        <Route path='/adaptivePractice' element={<AdaptivePractice></AdaptivePractice>}></Route>
        <Route path='/dailyChallenge' element={<AdaptiveDailyChallengeForGrade1To5Students></AdaptiveDailyChallengeForGrade1To5Students>}></Route>
        <Route path='*' element={<PageNotFound></PageNotFound>}></Route>
      </Routes>
    </Router>
  </AppContext.Provider>);
}

export default App;
