import "./header.css"
interface IHeader{
    text:string
    isCapital?:boolean
}

export function MainHeader(props:IHeader) {
    return(<div style={props.isCapital===false?{}:{textTransform:"capitalize"}} className='mainHeading'>{props.text}</div>)
}

export function HeaderMD(props:IHeader) {
    return(<div className='headerMd'>{props.text}</div>)
}

export function HeaderSM(props:IHeader) {
    return(<div className='headerSm'>{props.text}</div>)
}

export function HeaderLG(props:IHeader) {
    return(<div className='headerLg'>{props.text}</div>)
}