import { AdaptiveChallengeContext, AdaptivePracticeContext, AppContext } from "../../context/context";
import { SpaceFrame } from "./frame/space";
import { ContentFactoryImpl, Content } from '@mathbuddy/adaptive-practice-engine';
import { decryptData, getTimeSpent, isTokenValid } from '../../utils/utils';
import { Component } from "react";
import { StatusCodes } from "http-status-codes";
import apis from "../../apis/api";
import { ls } from "../../localStorage";
import { IChallengeInfo, ICurrentUser } from "../../interface/interface";
import { adaptivePracticeActions } from "../../reduxStore";
import { connect } from "react-redux";

class AdaptiveDailyChallengeForGrade1To5Students extends Component<any, any> {
    static contextType = AppContext;
    context!: { windowHeight: string, windowWidth: string, messageForParent: any, navigateTo: any };
    constructor(props: any) {
        super(props)
        this.state = {
            currentUser: {},
            langObj: { number_lang: '', lang: '' },
            challengeInfo: {},
            contentFactoryObj: new ContentFactoryImpl(),
            contentObj: new Content(),
            isNewSession: true,
            isLoader: false,
            isCoinAnimation: false,
            coins: 0,
            streaks: 0,
            adaptivePracticeLevel: '',
            completedStatusPercentage: 0,
            currentQuestionPosition: 0,
            startTime: 0,
            totalCorrectAttemptedQuestion: 0,
            totalAttemptedQuestions: 0,
            accuracy: 0,
            notificationMessage: '',
            response: { component: [] },
            questionSkill: {},
            isShowAdaptivePopup: false,
            isShowStartPopup: false,
            isCloseFromClick: false,
        }
    }

    componentDidMount = () => {
        this.verifyAndSetData();
    }

    verifyAndSetData = async () => {
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const QUERY: { currentUser: ICurrentUser, challengeInfo: IChallengeInfo } | any = queryParams.get("query");
            const lang: string | any = queryParams.get("lang");
            // console.log(QUERY);
            let query: { currentUser: ICurrentUser, challengeInfo: IChallengeInfo, lang: string } = decryptData(QUERY);

            // console.log(query);
            // console.log(isTokenValid(query.currentUser.token));

            if (ls.isValid(query) && ls.isValid(lang) &&
                ls.isValid(query.currentUser) &&
                ls.isValid(query.challengeInfo) &&
                isTokenValid(query.currentUser.token)) {
                // console.log("setLS")
                ls.setToken(query.currentUser.token);
                ls.setDBToken(query.currentUser.dbToken);
                let country_code = query.currentUser.isB2C ? query.currentUser.country_code : query.currentUser.classroom_country_code;
                this.setState(
                    {
                        currentUser: query.currentUser,
                        challengeInfo: query.challengeInfo,
                        langObj: { number_lang: 'en', lang: lang + '_' + country_code.toLowerCase() },
                        totalCorrectAttemptedQuestion: query.challengeInfo.correct,
                        totalAttemptedQuestions: query.challengeInfo.attempted
                    }, () => {
                        this.getLatestStateOnMount();
                    })
            }
            else {
                console.log("error while setting local storage");
                this.context.messageForParent('close');
                this.context.navigateTo('/pageNotFound');
            }
        } catch (error) {
            console.log("error while setting local storage");
            this.context.messageForParent('close');
            this.context.navigateTo('/pageNotFound');
        }
    }

    getLatestStateOnMount = async () => {
        await this.sendChallengeResponseIfAny();// If challenge result is not saved to db and it is there in redux send it db and clear redux.
        this.context.messageForParent('updateUserReducerForDailyChallenge');// on refresh if privious question is there in redux ask parent to update it.

        this.setState({ isLoader: true });
        let streaksAndCoins = await this.getStreaksAndCoins();

        this.setState({ ...streaksAndCoins, isLoader: false }, () => {
            this.loadChallengeContent();
        })
    }

    getStreaksAndCoins = async () => {
        let returnObj = { coins: 0, streaks: 0 };

        let currentUser = this.state.currentUser;
        let dashboardSummary = await apis._getLastUserSummary(currentUser.id);
        if (dashboardSummary.status === StatusCodes.OK && dashboardSummary.data.result.length > 0) {
            let lastSummary = dashboardSummary.data.result[0];

            returnObj.coins = lastSummary.total_coins
            returnObj.streaks = lastSummary.current_streak;
        }

        return returnObj;
    }

    loadChallengeContent = async () => {
        // console.log(this.props);
        this.setState({ isLoader: true });
        let challengeInfo: IChallengeInfo = this.state.challengeInfo;

        if (challengeInfo.questions.length === 0) {
            let currentUser: ICurrentUser = this.state.currentUser;
            let userObj = {
                env: process.env.REACT_APP_ENV, userId: currentUser.id,
                classroomId: currentUser.classroom_id,
                dbToken: currentUser.dbToken, token: ls.getToken()
            };

            let questionsCount = challengeInfo.total - challengeInfo.attempted;
            let challenge = await this.state.contentObj.getDailyQuestions(userObj, questionsCount,
                this.state.langObj.lang);
            // console.log(challenge);
            let questions = challenge[0];
            challengeInfo = { ...challengeInfo, questions };
            this.setState({ challengeInfo });
            this.context.messageForParent('updateChallengeInfo', challengeInfo);
            return; //window will reload after updating to parent
            /*this.props.setChallengeInfo(challengeInfo);*/
        }

        let question = challengeInfo.questions[this.state.currentQuestionPosition];
        let langObj: { number_lang: string, lang: string } = this.state.langObj;

        await this.state.contentObj.loadSkillBySkillId(question.skillId, false, langObj.lang);
        let response = await this.state.contentFactoryObj.displayQuestion(this.state.contentObj,
            this.state.contentObj.createContentTemplateObject(question.questionId, 'REACT_WEB'), langObj);
        // console.log(response);
        let adaptivePracticeLevel = 'Question ' + (this.state.totalAttemptedQuestions + 1);
        let completedStatusPercentage = Math.round(((this.state.totalAttemptedQuestions) * 100) / challengeInfo.total);
        let accuracy = Math.round((this.state.totalCorrectAttemptedQuestion * 100) / this.state.totalAttemptedQuestions) || 0;
        this.setState({ startTime: new Date(), response, adaptivePracticeLevel, completedStatusPercentage, accuracy },
            () => {
                //console.log(this.state);
                this.setState({ isLoader: false });
            })
    }

    getNotificationMessage = (streak: number) => {
        let message = ''
        if (streak > 0 && (streak % 2) === 0) {
            message = (streak < 10) ? `${streak} in a row!` :
                (streak < 20) ? `You are on a roll! ${streak} in a row` :
                    (streak <= 22) ? `Bravo! ${streak} in a row` :
                        `Way to go. ${streak} in a row`;
        }
        return message;
    }

    saveChallengeResponse = async (answerObj: { givenAnswer: any, actualAnswer: any, isCorrectAns: Boolean }, clickCount: number) => {
        let challengeInfo: IChallengeInfo = this.state.challengeInfo;
        let challengeQuestions = challengeInfo.questions;
        let coins = (answerObj.isCorrectAns) ? this.state.coins + 1 : this.state.coins;
        let streaks = (answerObj.isCorrectAns) ? this.state.streaks + 1 : 0;
        let notificationMessage = this.getNotificationMessage(streaks);
        let totalCorrectAttemptedQuestion = (answerObj.isCorrectAns) ? this.state.totalCorrectAttemptedQuestion + 1 : this.state.totalCorrectAttemptedQuestion;
        let totalAttemptedQuestions = this.state.totalAttemptedQuestions + 1;
        let isLastQuestion = (totalAttemptedQuestions < challengeInfo.total) ? false : true;
        let completedStatusPercentage = (!answerObj.isCorrectAns && !isLastQuestion) ? this.state.completedStatusPercentage : Math.round(((totalAttemptedQuestions) * 100) / challengeInfo.total);
        let isShowAdaptivePopup = isLastQuestion && answerObj.isCorrectAns;
        let accuracy = Math.round((totalCorrectAttemptedQuestion * 100) / totalAttemptedQuestions) || 0;

        this.setState({
            isCoinAnimation: answerObj.isCorrectAns, coins, streaks, completedStatusPercentage,
            notificationMessage, totalAttemptedQuestions, totalCorrectAttemptedQuestion, accuracy
        },
            () => {
                setTimeout(() => {
                    this.setState({ isCoinAnimation: false, isShowAdaptivePopup, notificationMessage: '' })
                    if (answerObj.isCorrectAns && this.state.currentQuestionPosition < challengeQuestions.length)
                        this.loadChallengeContent();
                }, 1600)
            });

        let currentQuestion = challengeQuestions[this.state.currentQuestionPosition];
        let data = {
            "isNewSession": this.state.isNewSession,
            "isReAttempt": false,
            "user_id": this.state.currentUser.id,
            "lo_id": currentQuestion.skillId,
            "question_id": currentQuestion.questionId,
            "review_obj": this.state.response.reviewObj,
            "number_lang": this.state.langObj.number_lang,
            "lang": this.state.langObj.lang,
            "answers": [{ "isCorrect": answerObj.isCorrectAns, "answer": answerObj.givenAnswer }],
            "isCorrect": answerObj.isCorrectAns,
            "isChallenge": true,
            "isChallengeCompleted": isLastQuestion,
            "blooms": this.state.response.blooms,
            "time_spent": getTimeSpent(this.state.startTime),
            "current_level": "Mastered",
            "classroom_id": this.state.currentUser.classroom_id,
        }
        let challengeResult: any[] = this.props.adaptivePracticeReducer.challenge.result;
        challengeResult = [...challengeResult, data];
        this.props.setChallengeResult(challengeResult);
        // console.log(challengeResult);

        let currentQuestionPosition = this.state.currentQuestionPosition + 1;

        this.setState({ currentQuestionPosition, isNewSession: false }, () => {
            if (isLastQuestion && challengeResult.length > 0)
                this.saveDailyChallengeQuestionsToDB(challengeResult);
        })
    }

    saveDailyChallengeQuestionsToDB = async (challengeResult: any[]) => {
        let response = await apis._saveQuestionResponse({ "questions": challengeResult });
        //here await is need becouse if you close in between then in daily challenge page it will not show correct attempted count.
        // let dailySM = new StoreManager(this.props.userReducer);
        // dailySM.updateUserReducerForDailyChallenge(this.state.challengeResult);
        // dailySM.sendBadgeAchievement();
        // this.props.setUserReducer(dailySM.getUserReducer());
        // this.props.setChallengeAttempted(this.state.totalAttemptedQuestions);
        // this.props.setChallengeCorrect(this.state.totalCorrectAttemptedQuestion);
        if (response?.status === StatusCodes.OK) {
            this.props.setChallengeResult([]);
            this.context.messageForParent('saveChallengeResult', {
                challengeResult: challengeResult
            })
        }
    }


    getPopUpInfo = () => {
        let accuracy = this.state.accuracy;
        let remainingQuestion = this.state.challengeInfo.total - this.state.totalAttemptedQuestions;
        let heading = (remainingQuestion > 0) ? `${remainingQuestion} more questions to go. Are you sure` :
            (accuracy === 0) ? 'You need to practice a lot' :
                (accuracy < 50) ? 'Can do better with practice' :
                    (accuracy < 80) ? 'Keep practicing. You can do better' :
                        (accuracy < 90) ? 'You did well. Try to score 100%' :
                            'Way to go! You were perfect!';

        let showAnimation = this.state.challengeInfo.questions.length &&
            remainingQuestion == 0 ? true : false;

        return {
            streaks: this.state.streaks,
            accuracy: accuracy,
            totalAttemptedQuestions: this.state.totalAttemptedQuestions,
            completedStatusPercentage: this.state.completedStatusPercentage,
            isShowStartPopup: false,
            isCloseFromClick: this.state.isCloseFromClick,
            stopBtnText: showAnimation ? 'OK' : undefined,
            heading, showAnimation
        }
    }

    setShowAdaptivePopup = (isCloseFromClick: Boolean = false) => {
        let isShowAdaptivePopup = !this.state.isShowAdaptivePopup
        this.setState({ isCloseFromClick, isShowAdaptivePopup });
    }

    sendChallengeResponseIfAny = async () => {
        let challengeResult = this.props.adaptivePracticeReducer.challenge.result
        if (challengeResult.length > 0) {
            this.setState({ isLoader: true });
            await this.saveDailyChallengeQuestionsToDB(challengeResult);
            this.setState({ isLoader: false });
        }
    }

    onClose = async () => {
        await this.sendChallengeResponseIfAny();
        this.context.messageForParent('close');

    }

    render() {
        return (
            <AdaptivePracticeContext.Provider value={{
                notificationMessage: this.state.notificationMessage,
                coins: this.state.coins,
                streaks: this.state.streaks,
                meter: {
                    percentage: this.state.completedStatusPercentage,
                    level: this.state.adaptivePracticeLevel
                },
                isLoader: this.state.isLoader,
                isCoinAnimation: this.state.isCoinAnimation,
                isShowStartPopup: false,
                isShowAdaptivePopup: this.state.isShowAdaptivePopup,
                isCloseFromClick: this.state.isCloseFromClick,
                engineObj: this.state.response,
                loadContent: this.loadChallengeContent,
                saveQuestionResponse: this.saveChallengeResponse,
                getPopUpInfo: this.getPopUpInfo,
                setShowAdaptivePopup: this.setShowAdaptivePopup,
                setShowStartPopup: () => { },
                onclose: this.onClose
            }}>
                <AdaptiveChallengeContext.Provider
                    value={{
                        isChallenge: true,
                        questions: this.state.challengeInfo.questions,
                        date: this.state.challengeInfo.date,
                        attempted: this.state.totalAttemptedQuestions,
                        total: this.state.challengeInfo.total,
                        correct: this.state.challengeInfo.correct
                    }}>
                    <SpaceFrame></SpaceFrame>
                </AdaptiveChallengeContext.Provider>
            </AdaptivePracticeContext.Provider>
        )
    }
}

function mapStateToProps(state: any) {
    return state;
}

let setChallengeResult = adaptivePracticeActions.setChallengeResult;

const mapDispatchToProps = { setChallengeResult };

export default connect(mapStateToProps, mapDispatchToProps)(AdaptiveDailyChallengeForGrade1To5Students);