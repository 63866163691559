import { Dispatch } from "react";

class AdaptivePracticeActions {

    setAPLastAttempted = (data: {}) => {
        return (dispatch: Dispatch<any>) => {
            return new Promise<void>((resolve, reject) => {
                dispatch({
                    type: 'SET_AP_LAST_ATTEMPTED',
                    payload: data
                })
                resolve()
            });
        }
    }

    setAPDefaultLastAttempted = () => {
        return (dispatch: Dispatch<any>) => {
            return new Promise<void>((resolve, reject) => {
                dispatch({
                    type: 'SET_AP_DEFAULT_LAST_ATTEMPTED',
                    payload: {}
                })
                resolve()
            });
        }
    }

    setIsChallengeResultSaved = (status: boolean) => {
        return (dispatch: Dispatch<any>) => {
            return new Promise<void>((resolve, reject) => {
                dispatch({
                    type: 'SET_AP_IS_CHALLENGE_RESULT_SAVED',
                    payload: status
                })
                resolve()
            });
        }
    }

    setChallengeResult = (data: {}) => {
        return (dispatch: Dispatch<any>) => {
            return new Promise<void>((resolve, reject) => {
                dispatch({
                    type: 'SET_AP_CHALLENGE_RESULT',
                    payload: data
                })
                resolve()
            });
        }
    }

}

export const adaptivePracticeActions = new AdaptivePracticeActions();