import {ReactEvent} from "@mathbuddy/adaptive-practice-events";
import { IConfig } from "./Render";
export const Button = ({className, id, style, children, clickedVal, actualVal,clickable,extraProps}:IConfig)=>
{
    const onClick = ()=>{
        new ReactEvent({id,clickedVal,actualVal,extraProps}).handleEvent(extraProps.event);
    }

    return (
        
        <button id={id} style={style} onClick ={()=>onClick()} className={className}>{children}</button>
    );

}