import React, { useRef, useEffect } from 'react';
import backgroundSound from './background.mp3'
import coinSound from './coin.mp3'
import expSound from './exp.mp3'
import hintSound from './hint.wav'
import successSound from './successfull.mp3'
    
const AdaptiveSound = (props:{type:string}) => {
    // the audio variable needs to be stored in a ref in order to access it across renders
    let audio:any = useRef();
    // start the audio (using the .current property of the ref we just created) when the component mounts using the useEffect hook
    useEffect(() => {
        switch (props.type.split("_")[0]) {
            case 'backgroundSound':
                audio.current = new Audio(backgroundSound);
                audio.current.volume = 7 / 100;
                break;
            case 'coinSound':
                audio.current = new Audio(coinSound);
                break;
            case 'expSound':
                audio.current = new Audio(expSound);
                audio.current.volume = 40 / 100;
                break;
            case 'hintSound':
                audio.current = new Audio(hintSound);
                audio.current.volume = 40 / 100;
                break;
            case 'successSound':
                audio.current = new Audio(successSound);
                audio.current.volume = 30 / 100;
                break;
            default:
                break;
        }
        audio.current.play()
    }, [props.type])
    // Stop the audio when the component unmounts
    // (not exactly what you asked re React Router, but similar idea)
    useEffect(() => {
        return () => {
            audio.current.pause()
            console.log("in cleanup")
        }
    }, [props.type])

    return (<></>)
}
export default AdaptiveSound;