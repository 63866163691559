import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faThumbsUp, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
library.add(faSpinner, faThumbsUp);
interface IIcon {
    color?: string
}


export function FASpinner(props: IIcon) {
    return (<div className='w-100 d-flex align-items-center justify-content-center' style={{ height: '100%' }}><FontAwesomeIcon icon={faSpinner as IconProp} spin={true} size="xl" color={props.color ? props.color : 'var(--secondary-purple)'} /></div>)
}

export function FAThumbsUp(props: IIcon) {
    return (<FontAwesomeIcon icon={faThumbsUp as IconProp} size="lg" color={props.color ? props.color : 'var(--secondary-green)'} />)
}

export function FAThumbsUpCircle(props: IIcon) {
    return (<FontAwesomeIcon icon={faThumbsUp as IconProp} size="lg" style={{ border:'1px solid #14CB12',borderRadius:'25px',backgroundColor: '#14CB12',padding:'5px 5px 5px 7px' ,transform: 'rotateY(180deg)'}} color={props.color ? props.color : 'white'} />)
}

export function FATickMark(props: IIcon) {
    return (<FontAwesomeIcon icon={faCheckCircle as IconProp} size="lg"  color={props.color ? props.color : '#14CB12'} />)
}