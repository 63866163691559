import axios from 'axios';
import { ls } from '../localStorage';
import jwt_decode from "jwt-decode";
import { StatusCodes } from 'http-status-codes';

const LOGIN_URL=process.env.REACT_APP_LOGIN_URL+'/login/v3.0';
const UNIT_PLAN_NJS_URL=process.env.REACT_APP_UNIT_PLAN_NJS_URL+'/unit-plan/v4.0';
const SKILLS_URL=process.env.REACT_APP_SKILLS_URL+'/skills';
const QUIZZES_PLAY_URL=process.env.REACT_APP_QUIZZES_PLAY_URL+'/quizPlay/v3.0';
const ACTIVITIES_PLAY_URL=process.env.REACT_APP_ACTIVITIES_PLAY_URL+'/activities/v3.0';
const USER_NJS_API_URL=process.env.REACT_APP_USER_NJS_URL+'/users';
const SMARTSHEETS_PLAY_URL=process.env.REACT_APP_SMARTSHEETS_PLAY_URL+'/smartSheetPlay/v3.0';
const USERS_URL=process.env.REACT_APP_USERS_URL+'/users/v3.0';
const CORES_URL=process.env.REACT_APP_CORES_URL+'/cores/v3.0';
const PLAY_NJS_URL=process.env.REACT_APP_PLAY_URL+'/play-service/v4.0';


const checkIfTokenRefreshNeeded = async () => {
    let token =ls.getToken();
    let decode_token:any =jwt_decode(token)
    var currentTimeSeconds = Math.round(+new Date() / 1000);
    // console.log(decode_token);
    if (decode_token.exp < currentTimeSeconds) {
      try {
        // console.log("Token has expired");
        let res= await apis._refreshToken();
        // console.log(res);
        if(res.status===StatusCodes.OK)
        {
            token = res.data.token;
            ls.setToken(token);
        }
      }
      catch (e) {
        console.log("Unable to refresh token" + e);
      }
    }
}

axios.interceptors.request.use(
    async req => {
      await checkIfTokenRefreshNeeded();
      req.headers = {
        'x-access-token': ls.getToken(),
        'x-access-db': ls.getDBToken()
      }
      return (await req);
    },
    (error) => {
        if (error?.response) {
            return error.response.data;
        }
        else if (error?.request) {
            return error.request;
        }
    }
);

  axios.interceptors.response.use(  
        function (response) {
            if(response?.data?.token)
             {
                 ls.setToken(response?.data?.token);
             }
            if(response.status>=200 && response.status<300){
            //  console.log(`status 2xx`, response);
            }
          return response;
        },
        function (error) {
            if (error?.response) {
               console.log("error",error.response)
               if(error.response.status!==404){
                callAgain(error.response.config,3)
               } 
                return error.response.data;
            }
            else if (error?.request) {
                return error.request;
            }  
          return Promise.reject(error);
        }
      );

      async function callAgain(requests:any, maxRetries = 3) {
        // console.log("Retrying API requests:", requests);
      
        async function makeRequest(config:any, retryCount:number) {
            // console.log("config should be here : ",config)
          try {
            const uninterceptedAxiosInstance:any = axios.create();
            console.log(`Retrying (${retryCount} attempts left)...`);
            const response = await uninterceptedAxiosInstance({
                          method: config.method,
                          url: config.url,
                          headers: config.headers,
                          data:config.data,
                        })
            // console.log("Retried: status", response);
          } catch (error) {
            console.error('Retried: Error', error);   
            if (retryCount > 1) { 
               await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
               await callAgain(config,retryCount - 1);
             } else {
               console.log("Max retries reached. Unable to get API : ",config);
             }
          }
        }
          await makeRequest(requests, maxRetries); 
      }
 
const apis ={
_initializeSession : async (token:string)=>{
    let isOffLineSchool = false;
    let isMobileAPP =false;
    ls.setToken(token);
    const uninterceptedAxiosInstance = axios.create();
    const res = await uninterceptedAxiosInstance.get(`${LOGIN_URL}/session/init/${token}/${isOffLineSchool}/${isMobileAPP}?${new Date().toISOString()}`)
    .catch(function (error) {

        if (error.response) {
            return error.response.data;
        }
        else if (error.request) {
            return error.request;
        }
    });
    return res; 
},
_refreshToken : async ()=>{
    let isMobileAPP =false;
    const uninterceptedAxiosInstance = axios.create();
    const res = await uninterceptedAxiosInstance.get(`${LOGIN_URL}/token/refresh/${isMobileAPP}`,{ headers: {'x-access-token': ls.getToken() }})
    .catch(function (error) {

        if (error.response) {
            return error.response.data;
        }
        else if (error.request) {
            return error.request;
        }
    });
    return res; 
},
_refreshSession:async ()=>{
    const res = await axios.get(`${LOGIN_URL}/session/refresh/false`);
    return res; 
},
_resetPassword:async (data:{password:string,user_id:string})=>{
    const res = await axios.put(`${USERS_URL}/resetPassword`,data);
    return res; 
},
_getAvatars:async (data:any)=>{
    const res = await axios.post(`${USERS_URL}/avatars`,data);
    return res; 
},
_getDailyGoalList:async (data:any)=>{
    const res = await axios.post(`${UNIT_PLAN_NJS_URL}/daily-goal/list`,data);
    return res; 
},

_getDailyGoal:async (id:string)=>{
    const res = await axios.get(`${UNIT_PLAN_NJS_URL}/daily-goal/user/${id}`);
    return res; 
},

_setDailyGoal:async (data:any)=>{
    const res = await axios.post(`${UNIT_PLAN_NJS_URL}/daily-goal/user`,data);
    return res; 
},

_saveQuestionResponse : async (data:any)=>{
    const res = await axios.post(`${PLAY_NJS_URL}/adaptive-practice/question`,data);
    return res; 
},

_getSkillStatus : async (data:any)=>{
    const res = await axios.post(`${UNIT_PLAN_NJS_URL}/adaptive-practice/skill/status/list`,data);
    return res; 
},
_getLastUserSummary : async (userId:string)=>{
  const data=  {
        "filterList": [
            {
                "columnName": "user_id",
                "type": "filterId",
                "value": [userId]
            }
        ],
        "sortHeader": "report_date",
        "sortDirection": "DESC",
        "page": 1,
        "limit": 1,
        "isPagination": true
    }
    const res = await axios.post(`${UNIT_PLAN_NJS_URL}/adaptive-practice/user/summary`,data);
    return res; 
},
_getDashboardSummary : async (id:string,unitPlanId:string,grade:number,isB2B:Boolean)=>{
    const res = await axios.get(`${UNIT_PLAN_NJS_URL}/adaptive-practice/student/dashboard-summary/${id}/${unitPlanId}/${grade}/${isB2B}`);
    return res; 
},
_getTodaysUserAchievements : async (uId:string)=>{
    const res = await axios.get(`${UNIT_PLAN_NJS_URL}/adaptive-practice/daily-user-achievements/${uId}`);
    return res; 
},
_getDailyUserAchievements : async (data:any)=>{
    const res = await axios.post(`${UNIT_PLAN_NJS_URL}/adaptive-practice/daily-user-achievements`,data);
    return res; 
},
_getDailyQuestionList : async (data:any)=>{
    const res = await axios.post(`${UNIT_PLAN_NJS_URL}/adaptive-practice/question/list`,data);
    return res; 
},
_getBadges : async (userId:string)=>{
    const res = await axios.get(`${PLAY_NJS_URL}/user-badges`);
    return res; 
},
_setBadgeAchievement : async (data:[])=>{
    const res = await axios.put(`${PLAY_NJS_URL}/user-badges/user-badge-achievement`,{badgeAchievement:data});
    return res; 
},
_getClassroomsWallOfFame : async (data:{classroom_id:string,isWeekly: boolean})=>{
    const res = await axios.post(`${UNIT_PLAN_NJS_URL}/classrooms/wall-of-fame`,data);
    return res; 
},
_getActiveTopicList : async (data:any)=>{
    const res = await axios.post(`${SKILLS_URL}/v4.0/topics/active`,data);
    return res; 
},
_getActiveQuizzes : async ()=>{
    const res = await axios.get(`${SKILLS_URL}/v3.0/fetchActiveQuizzesOfStudent`);
    return res; 
},
_getLOsTopicWise: async (data:any)=>{
    const res = await axios.post(`${SKILLS_URL}/v4.0/fetchLOsTopicWise`,data);
    return res; 
},
_getLOContentForStudent : async (data:any)=>{
    const res = await axios.post(`${SKILLS_URL}/v4.0/fetchLOContentForStudent`,data);
    return res; 
},
_getActivityInfo : async (data:any)=>{
    const res = await axios.post(`${SKILLS_URL}/v4.0/fetchActivityInfo`,data);
    return res; 
},
_isSmartSheetTaken : async (data:any)=>{
    // {"smart_sheet_id":"5c9486124c164b0d4ca6d2aa","country_code":"IN"}
    const res = await axios.post(`${SMARTSHEETS_PLAY_URL}/isSmartSheetTaken/`,data);
    return res; 
},
_isRatedSmartsheet : async (data:any)=>{
    const res = await axios.post(`${SMARTSHEETS_PLAY_URL}/isRatedSmartsheet/`,data);
    return res; 
},
_fetchSmartSheet : async (data:any)=>{
    const res = await axios.post(`${SMARTSHEETS_PLAY_URL}/fetchSmartSheet/`,data);
    return res; 
},
_saveSmartSheetResult : async (data:any)=>{
    const res = await axios.post(`${SMARTSHEETS_PLAY_URL}/saveResult/`,data);
    return res; 
},
_saveRatesSmartsheet : async (data:any)=>{
    const res = await axios.post(`${SMARTSHEETS_PLAY_URL}/ratesSmartsheet/`,data);
    return res; 
},
_isQuizTaken : async (data:any)=>{
    const res = await axios.post(`${QUIZZES_PLAY_URL}/isQuizTaken/`,data);
    return res; 
},
_fetchQuiz : async (data:any)=>{
    const res = await axios.post(`${QUIZZES_PLAY_URL}/fetchQuiz/`,data);
    return res; 
},
_saveQuizResult : async (data:any)=>{
    const res = await axios.post(`${QUIZZES_PLAY_URL}/saveResult/`,data);
    return res; 
},
_getActivityInfoForStudent : async (data:any)=>{
    const res = await axios.post(`${ACTIVITIES_PLAY_URL}/fetchActivityInfoForStudent/`,data);
    return res; 
},
_setCurrentTopic : async (uId:string,data:any)=>{
    const res = await axios.put(`${USER_NJS_API_URL}/v5.0/topic/${uId}`,data);
    return res; 
},
_setAvatar : async (uId:string,data:{level:number})=>{
    const res = await axios.put(`${USER_NJS_API_URL}/v5.0/avatar/${uId}`,data);
    return res; 
},
_getActivityStatus : async (data:any)=>{
    const res = await axios.post(`${ACTIVITIES_PLAY_URL}/isActivityCompleted/`,data);
    return res; 
},
_getQuarterlyReport: async (classRoomId:string,month:number,year:number)=>{
    const res=await axios.get(`${UNIT_PLAN_NJS_URL}/report/quarterly/student/classroomWise/${year}/${month}/${classRoomId}`)
    return res;
},
_getMentalMathLevel: async (data:{classroom_id?:string,lo_id:string,user_id:string})=>{
    const res=await axios.post(`${PLAY_NJS_URL}/mental-math/level`,data);
    return res;
},
_getMentalMathHighScore: async (loId:string, qId:string)=>{
    const res=await axios.get(`${PLAY_NJS_URL}/mental-math/high-score/${loId}/${qId}`);
    return res;
},
_saveMentalMathQuestions: async (data:{ questions:any[], stars: number })=>{
    const res=await axios.post(`${PLAY_NJS_URL}/mental-math/question`,data);
    return res;
}
}
export default apis;