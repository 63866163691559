import { Player } from "@lottiefiles/react-lottie-player";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/context";
import './masterPopup.css';
import { IInfo, IPopUp } from "../../interface/interface";

export function MasterPopup(props: IPopUp) {

    useEffect(() => {
        setInfo(props.info);
        setShowAnimation(props.info.showAnimation);
    }, [])

    const appContext: any = useContext(AppContext);
    const [showAnimation, setShowAnimation] = useState(false);
    const [info, setInfo] = useState<IInfo | any>({});

    useEffect(() => {
        setTimeout(() => {
            setShowAnimation(false)
        }, 5000)
    }, [showAnimation])

    return (<div className="popup d-flex align-items-center justify-content-center">
        {(showAnimation) && (
            <img className="mastryAnimation" src={"./assets/images/themes/space/mastry_animation.gif?" + new Date().toDateString()}></img>
        )}
        <div className="skillMasterPopup d-flex flex-column align-items-center justify-content-start"
            style={{ height: info.isShowStartPopup ? '60%' : '65%' }}>
            <div className="head d-flex align-items-center justify-content-center"
                style={{ height: info.isShowStartPopup ? '30%' : '20%' }}>{info.heading}</div>
            <div className="popupInfo row d-flex align-items-center justify-content-center margin_padding_none_strict"
                style={{ height: info.isShowStartPopup ? '40%' : '30%' }}>
                <div className="col-2 masterBoyWrapper">
                    <div className="skillMasterBoy">
                        <Player id={"boy"} style={{ "width": "100%", "height": "100%" }} className={"boy"} autoplay={true} loop={true} speed={1.5} src={"./assets/animation/themes/space/skill_master_boy.json"}></Player>
                    </div>
                </div>
                {(info.isShowStartPopup) && (
                    <div className="col-10 info2 d-flex flex-column align-items-center justify-content-evenly">
                        You will be given 10 questions from this skill. The questions are personalized for you based on your earlier attempts in this skill
                    </div>
                )}
                {(!info.isShowStartPopup) && (
                    <div className="col-10 info row margin_padding_none_strict">
                        <div className="col-6 d-flex flex-column align-items-center justify-content-evenly">
                            <span className="infoHead">Questions completed</span>
                            <span className="infoValue">{info.totalAttemptedQuestions}</span>
                        </div>
                        <div className="col-6 d-flex flex-column align-items-center justify-content-evenly">
                            <span className="infoHead">Accuracy</span>
                            <span className="infoValue">{info.accuracy}%</span>
                        </div>
                        {/* <div className="col-4 d-flex flex-column align-items-center justify-content-center">
                        <span className="infoHead">Longest Streak</span>
                        <span className="infoValue">10</span>
                    </div> */}
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            {(info.streaks > 1) && (<span style={{ fontSize: '1.2vw', color: '#FFF' }}>
                                You are on a current streak of {info.streaks} questions. Way to go!</span>)}
                        </div>

                    </div>)}

            </div>

            {(!info.isShowStartPopup) && (
                <div id="skillStatusContainer" className="d-flex flex-column align-items-center justify-content-evenly"
                    style={{ height: info.isCloseFromClick ? '20%' : '30%' }}>
                    <span>Completion Status</span>
                    <progress id="progressBar" max="100" value={info.completedStatusPercentage} />
                    {/* {(!info.isCloseFromClick && nextSkill.name) && (
                        <span>Next skill: {nextSkill.name}</span>
                    )} */}
                </div>
            )}

            <div id="btnContainer" className="d-flex align-items-center justify-content-center">
                {(!info.isShowStartPopup) && (
                    <button type="button" className="stop-btn stop-btn-bg d-flex align-items-center justify-content-center" onClick={props.onSkip}>
                        <a href="#">{info.stopBtnText ? info.stopBtnText : "Stop for Now"}</a>
                    </button>)}
                {(info.isShowStartPopup || info.isCloseFromClick) && (     //|| nextSkill.name
                    <button type="button" className="continue_btn_wrapper d-flex flex-column align-items-center justify-content-center"
                        onClick={info.isShowStartPopup ? props.onStart :
                            info.isCloseFromClick ? props.onContinue : props.onSkip}>
                        <div id={"continue-btn"}>
                            <Player style={{ "width": "100%", "height": "100%" }} className={"continue-btn"} autoplay={true} loop={true} speed={1.5} src={"./assets/animation/themes/space/done_button.json"}></Player>
                        </div>
                        <span id={"continue_btn_text"}>{info.isShowStartPopup ? 'Start Now' : info.isCloseFromClick ? " Continue Playing" : 'Start Next Skill'}</span>
                    </button>)}
            </div>

        </div>
    </div>)
}