import { AdaptivePracticeContext, AppContext } from "../../context/context";
import { SpaceFrame } from "./frame/space";
import { ContentFactoryImpl, Content } from '@mathbuddy/adaptive-practice-engine';
import { getTimeSpent } from '../../utils/utils';
import { Component } from "react";
import { StatusCodes } from "http-status-codes";
import apis from "../../apis/api";
import { ls } from "../../localStorage";
import { adaptivePracticeActions } from "../../reduxStore";
import { connect } from "react-redux";


class AdaptivePracticeForGrade1To5Students extends Component<any, any> {
    static contextType = AppContext;
    context!: { windowHeight: string, windowWidth: string, messageForParent: any, navigateTo: any };
    constructor(props: any) {
        super(props)
        this.state = {
            currentUser: this.props.currentUser,
            skillId: this.props.skillId,
            langObj: { number_lang: '', lang: '' },
            contentFactoryObj: new ContentFactoryImpl(),
            contentObj: new Content(),
            isPractice: false,
            isNewSession: true,
            isLoader: false,
            isCoinAnimation: false,
            coins: 0,
            streaks: 0,
            adaptivePracticeLevel: '',
            completedStatusPercentage: 0,
            currentQuestionPosition: 0,
            startTime: 0,
            totalCorrectAttemptedQuestion: 0,
            totalAttemptedQuestions: 0,
            totalAttemptedCount: 0,
            accuracy: 0,
            notificationMessage: '',
            response: { component: [] },
            questionSkill: {},
            lastResponses: { questions: [] },
            isShowAdaptivePopup: false,
            isShowStartPopup: false,
            isCloseFromClick: false,
        }
    }

    componentDidMount = () => {
        this.getLatestStateOnMount();
    }


    getLatestStateOnMount = async () => {
        await this.sendLastAttemptedIfAny(); // if window is reloaded and last attempted questions is there send it to DB.
        this.setState({ isLoader: true });
        let skillStatus = await this.getSkillStatus();
        let streaksAndCoins = await this.getStreaksAndCoins();
        let currentUser = this.props.currentUser;
        let country_code = currentUser.isB2C ? currentUser.country_code : currentUser.classroom_country_code;

        let langObj = { number_lang: 'en', lang: this.props.lang + '_' + country_code.toLowerCase() };
        this.setState({ ...skillStatus, ...streaksAndCoins, langObj, isLoader: false }, () => {
            // console.log(this.props);
            // console.log(this.state);
            if (!this.state.isShowStartPopup)
                this.loadSkillContent();
        })
    }

    getSkillStatus = async () => {
        let returnObj = {
            totalAttemptedCount: 0,
            totalCorrectAttemptedQuestion: 0,
            isPractice: false,
            isShowStartPopup: false,
        }

        let skillStatusData = {
            "filterList": [
                {
                    "columnName": "user_id",
                    "type": "filterId",
                    "value": [this.state.currentUser.id]
                },
                {
                    "columnName": "lo_id",
                    "type": "filterId",
                    "value": [this.state.skillId]
                }
            ],
            "sortHeader": "createdAt",
            "sortDirection": "DESC",
            "page": 1,
            "limit": 1,
            "isPagination": true
        }

        if (this.state.currentUser.classroom_id) {
            skillStatusData.filterList.push({
                "columnName": "classroom_id",
                "type": "filterId",
                "value": [this.state.currentUser.classroom_id]
            });
        }

        let skillStatusRes = await apis._getSkillStatus(skillStatusData);
        //console.log(skillStatusRes);
        if (skillStatusRes?.data?.code === StatusCodes.OK) {
            if (skillStatusRes.data.result.data.length > 0) {
                returnObj.totalAttemptedCount = skillStatusRes.data.result.data[0].questions
                returnObj.totalCorrectAttemptedQuestion = skillStatusRes.data.result.data[0].correct_answers
                returnObj.isPractice = skillStatusRes.data.result.data[0].current_level === "Mastered" ? true : false;
                returnObj.isShowStartPopup = returnObj.isPractice;
            }
        }

        return returnObj;
    }

    getStreaksAndCoins = async () => {
        let returnObj = { coins: 0, streaks: 0 };

        let currentUser = this.state.currentUser;
        let dashboardSummary = await apis._getLastUserSummary(currentUser.id);
        if (dashboardSummary.status === StatusCodes.OK && dashboardSummary.data.result.length > 0) {
            let lastSummary = dashboardSummary.data.result[0];

            returnObj.coins = lastSummary.total_coins
            returnObj.streaks = lastSummary.current_streak;
        }

        return returnObj;
    }

    loadSkillContent = async () => {
        try {
            let isPractice = this.state.isPractice;
            this.setState({ isLoader: true, response: { component: [] } })

            let currentUser = this.state.currentUser;
            let skillId = this.state.skillId;
            let userObj = {
                env: process.env.REACT_APP_ENV, userId: currentUser.id,
                classroomId: currentUser.classroom_id,
                dbToken: currentUser.dbToken, token: ls.getToken()
            };
            let currentQuestionPosition = (isPractice) ? this.state.currentQuestionPosition : 0;
            let langObj = this.state.langObj;
            // console.log(langObj);
            let passingResponses = (this.state.lastResponses.questions.length < 2) ? [] : this.state.lastResponses.questions.slice(-2);
            //  console.log("slicedArray",passingResponses)
            let questionSkill = (currentQuestionPosition === 0) ? await this.state.contentObj.loadSkill(skillId, userObj, langObj.lang, this.state.adaptivePracticeLevel, passingResponses) :
                this.state.questionSkill;

            //  console.log("question_skill",questionSkill,"currentlevel",questionSkill.currentLevel,"adaptivelevel",this.state.adaptivePracticeLevel,passingResponses);

            let questionId = questionSkill.questions[currentQuestionPosition];
            let adaptivePracticeLevel = (isPractice) ? 'Question ' + (this.state.currentQuestionPosition + 1)
                : questionSkill.currentLevel;
            let completedStatusPercentage = (isPractice) ? Math.round(((currentQuestionPosition) * 100) / questionSkill.questions.length) :
                Math.round(((questionSkill.meter.current - 1) * 100) / questionSkill.meter.total);
            let accuracy = Math.round((this.state.totalCorrectAttemptedQuestion * 100) / (this.state.totalAttemptedCount)) || 0;
            let totalAttemptedQuestions = (!isPractice) ? questionSkill.meter.current - 1 : this.state.totalAttemptedQuestions;


            let response = await this.state.contentFactoryObj.displayQuestion(this.state.contentObj,
                this.state.contentObj.createContentTemplateObject(questionId, 'REACT_WEB'), langObj);
            // console.log("response_skills", response, this.state.lastResponses);
            this.setState({
                startTime: new Date(), currentQuestionPosition, adaptivePracticeLevel, questionSkill,
                completedStatusPercentage, response, accuracy, totalAttemptedQuestions
            }, () => {
                //console.log(this.state);
                this.setState({ isLoader: false });
            })

        } catch (error) {
            this.setState({ isLoader: false })
        }
    }

    getNotificationMessage = (streak: number) => {
        let message = ''
        if (streak > 0 && (streak % 2) === 0) {
            message = (streak < 10) ? `${streak} in a row!` :
                (streak < 20) ? `You are on a roll! ${streak} in a row` :
                    (streak <= 22) ? `Bravo! ${streak} in a row` :
                        `Way to go. ${streak} in a row`;
        }
        return message;
    }

    saveQuestionResponse = async (answerObj: { givenAnswer: any, actualAnswer: any, isCorrectAns: Boolean }, clickCount: number) => {
        let QS = this.state.questionSkill;
        let isPractice = this.state.isPractice;
        let isCorrectAns = answerObj.isCorrectAns;

        let coins = (isCorrectAns) ? (this.state.coins + 1) : this.state.coins;
        let streaks = (isCorrectAns) ? (this.state.streaks + 1) : 0;
        let time_spent = getTimeSpent(this.state.startTime);
        let currentQuestionPosition = (isPractice && (isCorrectAns || clickCount > 1)) ? (this.state.currentQuestionPosition + 1) :
            (isPractice && !isCorrectAns && clickCount == 1) ? (this.state.currentQuestionPosition) : 0; //before mastry engine will give only one question at a time so it's 0
        let adaptivePracticeLevel = (!isPractice && isCorrectAns && clickCount == 1) ? QS.nextLevel : this.state.adaptivePracticeLevel;
        let completedStatusPercentage = (isPractice) ? Math.round(((currentQuestionPosition) * 100) / QS.questions.length) :
            (isCorrectAns && clickCount == 1) ? Math.round(((QS.meter.current) * 100) / QS.meter.total) :
                this.state.completedStatusPercentage;
        let notificationMessage = this.getNotificationMessage(streaks);
        let isMastered = (!isPractice && isCorrectAns && clickCount == 1 && QS.nextLevel === "Mastered") ? true : false;
        let isShowAdaptivePopup = (!isPractice && isCorrectAns && clickCount == 1 && QS.nextLevel === "Mastered") ? true :
            (isPractice && currentQuestionPosition === QS.questions.length) ? true : false;
        let totalCorrectAttemptedQuestion = (isCorrectAns) ? this.state.totalCorrectAttemptedQuestion + 1 : this.state.totalCorrectAttemptedQuestion;

        let totalAttemptedQuestions = (!isPractice && (isCorrectAns && clickCount === 1)) ? QS.meter.current
            : (isPractice && (isCorrectAns || clickCount > 1)) ? this.state.totalAttemptedQuestions + 1
                : this.state.totalAttemptedQuestions;

        let totalAttemptedCount = this.state.totalAttemptedCount + 1;
        let accuracy = Math.round((totalCorrectAttemptedQuestion * 100) / totalAttemptedCount) || 0;

        this.setState({
            isCoinAnimation: isCorrectAns, coins, streaks, completedStatusPercentage, currentQuestionPosition,
            adaptivePracticeLevel, notificationMessage, totalCorrectAttemptedQuestion, totalAttemptedQuestions, accuracy, totalAttemptedCount,
            isShowStartPopup: false, isCloseFromClick: false
        },
            () => {
                setTimeout(() => {
                    this.setState({ isCoinAnimation: false, notificationMessage: '', isShowAdaptivePopup })
                    /* if (isShowAdaptivePopup)
                       this.playMusic('successSound');*/
                }, 1700)
            });

        let lastAttemptedData = this.props.adaptivePracticeReducer.lastAttemptedQuestion;
        let currentAnswer = [{ "isCorrect": answerObj.isCorrectAns, "answer": answerObj.givenAnswer }];

        let data = {
            "questions": [{
                "isNewSession": this.state.isNewSession,
                "isReAttempt": false,
                "user_id": this.state.currentUser.id,
                "lo_id": this.state.skillId,
                "question_id": QS.questions[this.state.currentQuestionPosition],
                "review_obj": this.state.response.reviewObj,
                "number_lang": this.state.langObj.number_lang,
                "lang": this.state.langObj.lang,
                "answers": [...lastAttemptedData.questions[0].answers, ...currentAnswer],
                "isCorrect": answerObj.isCorrectAns,
                "isChallenge": false,
                "isChallengeCompleted": false,
                "blooms": this.state.response.blooms,
                "time_spent": lastAttemptedData.questions[0].time_spent + time_spent,
                "current_level": (answerObj.isCorrectAns == true && clickCount == 1) ? QS.nextLevel : QS.currentLevel,
                "classroom_id": this.state.currentUser.classroom_id,
            }]
        }

        if (clickCount === 1 && !answerObj.isCorrectAns) {
            this.props.setAPLastAttempted(data);
            return    // return from here if answer is incorrect in the first attempt 
        }

        let lastResponses = this.state.lastResponses;
        lastResponses.questions.push(data.questions[0])
        // console.log(data);

        this.setState({ lastResponses, isNewSession: false },
            async () => {
                let response = await this.saveQuestionResponseToDB(data, isMastered);
                // console.log(response);
                if (response?.status === StatusCodes.OK) {
                    if (isCorrectAns && !isShowAdaptivePopup)
                        this.loadSkillContent();
                }
            });
    }

    saveQuestionResponseToDB = async (data: any, isMastered = false) => {
        // console.log(data);
        let response = await apis._saveQuestionResponse(data);
        if (response?.status === StatusCodes.OK) {
            this.props.setAPDefaultLastAttempted();// set default last attempted question with empty answer array.
            this.context.messageForParent('updateUserReducerForAdaptivePractice', {
                QuestionAnswered: data.questions[0].answers.length, //This becouse we are storing one question at a time.
                coins: this.state.coins,
                time_spent: data.questions[0].time_spent,
                streaks: this.state.streaks,
                isMastered: isMastered,
                accuracy: this.state.accuracy
            })
        }
        return response;
    }

    getPopUpInfo = () => {
        let accuracy = this.state.accuracy;
        let remainingQuestion = (this.state.isPractice && !this.state.isShowStartPopup && this.state.questionSkill?.questions?.length) ?
            this.state.questionSkill.questions.length - this.state.totalAttemptedQuestions : 0;
        let heading = ''
        let showAnimation = false;
        switch (this.state.adaptivePracticeLevel) {
            case "Beginner":
                heading = "You are just getting started! Are you sure?"
                break;
            case "Developing":
                heading = "There are more questions to go! Are you sure?"
                break;
            case "Familiar":
                heading = "You are doing well! Are you sure?"
                break;
            case "Proficient":
                heading = "Just a few more questions to go! Are you sure?"
                break;
            case "Mastered":
                if (this.state.questionSkill.questions.length !== 0 && !this.state.isShowStartPopup)
                    showAnimation = true;
                heading = "Awesome! You have mastered this skill"
                break;
            default:
                heading = (this.state.isPractice && this.state.isShowStartPopup) ?
                    "You have already mastered this skill. You can get more practice with Math Buddy AI" :
                    (remainingQuestion > 0) ? `${remainingQuestion} more questions to go. Are you sure` :
                        (accuracy === 0) ? 'You need to practice a lot' :
                            (accuracy < 50) ? 'Can do better with practice' :
                                (accuracy < 80) ? 'Keep practicing. You can do better' :
                                    (accuracy < 90) ? 'You did well. Try to score 100%' :
                                        'Way to go! You were perfect!';
                if (this.state.questionSkill?.questions?.length && !this.state.isShowStartPopup && remainingQuestion === 0)
                    showAnimation = true;
                break;
        }

        return {
            streaks: this.state.streaks,
            accuracy: accuracy,
            totalAttemptedQuestions: this.state.totalAttemptedQuestions,
            completedStatusPercentage: this.state.completedStatusPercentage,
            isShowStartPopup: this.state.isShowStartPopup,
            isCloseFromClick: this.state.isCloseFromClick,
            heading, showAnimation
        }
    }

    setShowAdaptivePopup = (isCloseFromClick: Boolean = false) => {
        let isShowAdaptivePopup = !this.state.isShowAdaptivePopup
        this.setState({ isCloseFromClick, isShowAdaptivePopup });
    }

    setShowStartPopup = (status: boolean) => {
        this.setState({ isShowStartPopup: status })
        if (!status)
            this.loadSkillContent();
    }

    sendLastAttemptedIfAny = async () => {
        let lastAttemptedData = this.props.adaptivePracticeReducer.lastAttemptedQuestion;
        if (lastAttemptedData.questions[0].answers.length) {
            this.setState({ isLoader: true });
            await this.saveQuestionResponseToDB(lastAttemptedData);
            this.setState({ isLoader: false });
        }
    }

    onClose = async () => {
        await this.sendLastAttemptedIfAny();
        this.context.messageForParent('close');
    }

    render() {
        return (
            <AdaptivePracticeContext.Provider value={{
                notificationMessage: this.state.notificationMessage,
                coins: this.state.coins,
                streaks: this.state.streaks,
                meter: {
                    percentage: this.state.completedStatusPercentage,
                    level: this.state.adaptivePracticeLevel
                },
                isLoader: this.state.isLoader,
                isCoinAnimation: this.state.isCoinAnimation,
                isShowStartPopup: this.state.isShowStartPopup,
                isShowAdaptivePopup: this.state.isShowAdaptivePopup,
                isCloseFromClick: this.state.isCloseFromClick,
                engineObj: this.state.response,
                loadContent: this.loadSkillContent,
                saveQuestionResponse: this.saveQuestionResponse,
                getPopUpInfo: this.getPopUpInfo,
                setShowAdaptivePopup: this.setShowAdaptivePopup,
                setShowStartPopup: this.setShowStartPopup,
                onclose: this.onClose
            }}>
                <SpaceFrame></SpaceFrame>
            </AdaptivePracticeContext.Provider>
        )
    }
}

function mapStateToProps(state: any) {
    return state;
}

let setAPLastAttempted = adaptivePracticeActions.setAPLastAttempted;
let setAPDefaultLastAttempted = adaptivePracticeActions.setAPDefaultLastAttempted;


const mapDispatchToProps = { setAPLastAttempted, setAPDefaultLastAttempted };

export default connect(mapStateToProps, mapDispatchToProps)(AdaptivePracticeForGrade1To5Students);