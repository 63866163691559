import { useState } from "react";
import "./button.css"
import components from '../../../assets/data/components.json'
interface IButton {
    text: string,
    variant: number,
    onClick: any,
    active?: Boolean,
    height?: string,
    width?: string,
    disabled?:boolean
}
interface IDailyChallengeButton{
    text: string,
    onClick: any,
    isCompleted:Boolean,
    height?: string,
    width?: string
}
interface IconButton {
    type: string
    variant: number,
    onClick: any,
    height?: string,
    width?: string
}
interface IBackButton {
    onClick: any,
    height?: string,
    width?: string
}
interface ICloseButton {
    onClick: any,
    top?: string,
    right?: string,
    height?: string,
    width?: string
}
interface IButtonGroup {
    text: string[],
    variant: number,
    activePos: number,
    onClick: any,
    height?: string,
    width?: string
}

export function MainButton(props: IButton) {
    return (<button className={'main-button main-button-' + (props.active ? 'active' : 'v' + props.variant) + (props.disabled ? ' main-button-disabled':'') }
        style={{ height: props.height ? props.height : '', width: props.width ? props.width : '' }} onClick={props.onClick}>
        {props.text}
    </button>)
}

export function DailyChallengeButton(props: IDailyChallengeButton) {
    return (<button className='d-flex flex-column main-button main-button-v3'
        style={{ height: props.height ? props.height : '', width: props.width ? props.width : '' }} onClick={props.onClick}>
        <div>{props.text}</div>
        {(!props.isCompleted)&&(
          <div className="warn">Not completed <img src={components.baseURL+'warning-sign.svg'}></img></div>  
        )}
    </button>)
}

export function MenuButton(props: IButton) {

    let iconPath = `./assets/appImages/${props.active ? 'active' : 'inactive'}/${props.text.toLowerCase()}.svg`;
    let imageStyle =props.text.toLowerCase()==="leaderboard"
                    ?{ width: "60%"}:{};
    return (<button className={'menu-button menu-button-' + (props.active ? 'active' : 'v' + props.variant)}
        style={{ height: props.height ? props.height : '', width: props.width ? props.width : '' }} onClick={props.onClick}>
        <div className="d-flex flex-column flex-sm-row align-items-center" style={{ width: "90%", height: "100%" }}>
            <div className="menu-image-wrapper"><img className="menu-image" alt={props.text} src={iconPath} style={imageStyle}></img></div>
            <span className="d-none d-sm-flex" style={{ padding: 'var(--padding-xs)' }}>{props.text}</span>
        </div>
    </button>)
}

export function IconButton(props: IconButton) {
    let icon = components.icon.filter(ic=>ic.type===props.type)[0];
    
    return (<button className={'icon-button icon-button-v' + props.variant}
        style={{ height: props.height ? props.height : '', width: props.width ? props.width : '' }} onClick={props.onClick}>
        <div className={`d-flex ${props.variant == 1 ? '' : 'flex-column'} align-items-center justify-content-center`} style={{ width: "90%" }}>
            <span>{icon.text}</span>
            <div className="icon-image-wrapper"><img src={'./assets/appImages/'+icon.image}></img></div>
        </div>
    </button>)
}

export function ButtonGroup(props: IButtonGroup) {

    let [activePos, setActivePos] = useState(props.activePos);

    let toggleButton = (pos: number) => {
        setActivePos(pos);
        props.onClick(pos);
    }

    return (<div className={`button-group-v${props.variant} d-flex`}
        style={{ height: props.height ? props.height : '', width: props.width ? props.width : '' }}>

        {(props.text.map((text: string, ii: number) => {
            return (<button key={ii} className={activePos == ii ? 'active' : ''} onClick={() => { toggleButton(ii) }}>{text}</button>)
        }))}

    </div>)
}

export function BackButton(props: IBackButton) {
    return (<button className='back-button'
        style={{ height: props.height ? props.height : '', width: props.width ? props.width : '' }} onClick={props.onClick}>
        <img src={components.baseURL + "arrow-left-icon.svg"}></img>
    </button>)
}

export function SettingButton(props: IBackButton) {
    return (<button className='setting-button'
        style={{zIndex:2, height: props.height ? props.height : '', width: props.width ? props.width : '' }} onClick={props.onClick}>
        <img src={components.baseURL + "setting.svg"}></img>
    </button>)
}

export function CloseButton(props: ICloseButton) {
    return (<button className='close-button'
        style={{
            position: "absolute", top: props.top ? props.top : '', right: props.right ? props.right : '',
            height: props.height ? props.height : '', width: props.width ? props.width : ''
        }}
        onClick={props.onClick}>
        <img style={{ height: "100%" }} src={components.baseURL + "x-icon.svg"}></img>
    </button>)
}

export function SignOutButton(props:{onClick:any,height?:string,width?:string}) {
    return (<button className='sign-out-button'
        style={{ height: props.height ? props.height : '', width: props.width ? props.width : '' }} onClick={props.onClick}>
            Sign out
    </button>)
}