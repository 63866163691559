import { renderedContext } from '../context/context';
import { useContext } from "react";
import { IConfig } from "./Render";
import { CheckAnswerMentalMath } from './CheckAnswerMentalMath';
import { CheckAnswerAdaptivePractice } from './CheckAnswerAdaptivePractice';

export const CheckAnswer = ({ className, id, style, children, clickedVal, actualVal, clickable, extraProps }: IConfig) => {

    // console.log(extraProps);
    const state: any = useContext(renderedContext);

    return (<>
        {(state.type === 'mental-math') && (
            <CheckAnswerMentalMath
                id={id} className={className} style={style} children={children}
                clickedVal={clickedVal} actualVal={actualVal} clickable={clickable}
                extraProps={extraProps} key={''} component={''} size={0}
            />
        )}

        {(state.type === 'adaptive-practice') && (
            <CheckAnswerAdaptivePractice
                id={id} className={className} style={style} children={children}
                clickedVal={clickedVal} actualVal={actualVal} clickable={clickable}
                extraProps={extraProps} key={''} component={''} size={0}
            />
        )}

    </>)

}