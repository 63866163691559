import { IConfig } from "./Render";
export const SvgLine = ({className, id, style, extraProps}:IConfig)=>
{   
    return (
           <line id={id} className={className} style={style} 
           transform={extraProps.transform}
           x1={extraProps.x1} y1={extraProps.y1} x2={extraProps.x2} y2={extraProps.y2} 
           stroke={extraProps.stroke}  strokeWidth={extraProps.stroke_width}
           strokeLinecap={extraProps.stroke_linecap} markerStart={extraProps.marker_start}
           strokeDasharray={extraProps.stroke_dasharray}
           markerMid={extraProps.marker_mid} markerEnd={extraProps.marker_end}/>
    );
}