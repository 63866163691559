import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { IConfig } from "./Render";

export const Lottie = ({id, style, children, src, extraProps}:IConfig)=>
{
    return (
        
        <Player id={id} style={style} autoplay={extraProps.autoplay} loop={extraProps.loop} speed={extraProps.speed} src={src!}>{children}</Player>
    );

}