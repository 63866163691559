import './learnActivity.css'
import packageJson from '../../../package.json';
import { Dispatch, useEffect, useState } from 'react';
import { ICurrentUser, ILearnInfo, IReducer, IUserReducer } from '../../interface/interface';
import { ls } from '../../localStorage';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { decryptData, isTokenValid } from '../../utils/utils';
import { FASpinner } from '../../fontawesomeIcon/Fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { userAction } from '../../reduxStore';
import jwt_decode from "jwt-decode";
import { StatusCodes } from 'http-status-codes';
import apis from '../../apis/api';

interface IQuery {
    learnInfo: ILearnInfo,
    currentUser: ICurrentUser,
    loId: string,
    token: string,
    dbToken: string
}

export function LearnActivity() {

    useEffect(() => {
        verifyDataAndSetLocalStorage();
        return () => {
            clearPrerequisite();
            ls.clearLearnActivityData();
        }
    }, [])

    const dispatch: Dispatch<any> = useDispatch();
    const userReducer: IUserReducer = useSelector((state: IReducer) => state.userReducer);

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const QUERY: IQuery | any = queryParams.get("query");
    const activityId: string | any = queryParams.get("activityId");
    const lang: string | any = queryParams.get("lang");
    const loginToken: string | any = queryParams.get("token");

    const [playActivity, setPlayActivity] = useState(false);

    const verifyDataAndSetLocalStorage = async () => {
        try {
            // console.log(QUERY);
            let query: IQuery | any = {};

            if (QUERY) {
                query = decryptData(QUERY);
                dispatch(userAction.setCurrentUser(query.currentUser));
            }
            else {
                let decode_token: { userId: string } = jwt_decode(loginToken);
                if (userReducer.currentUser.id &&
                    decode_token.userId.toString() === userReducer.currentUser.id.toString()) {

                    query.currentUser = userReducer.currentUser;
                    let currentToken = ls.getToken();
                    if (!isTokenValid(currentToken)) {
                        let res = await apis._refreshToken();
                        if (res.status === StatusCodes.OK)
                            currentToken = res.data.token
                    }
                    query.token = currentToken;
                    query.dbToken = query.currentUser.dbToken;
                }
                else {
                    dispatch(userAction.setInitialState());
                    const init = await apis._initializeSession(loginToken);
                    // console.log(init)
                    if (init.status === StatusCodes.OK) {
                        query.currentUser = init.data.collection
                        query.token = query.currentUser.token;
                        query.dbToken = query.currentUser.dbToken;
                        dispatch(userAction.setCurrentUser(query.currentUser));
                    }
                }

                ls.setToken(query.token);
                ls.setDBToken(query.dbToken);
                let data = { "unique_id": activityId, "country_code": "IN" }
                let res = await apis._getActivityInfo(data);
                // console.log(res);

                if (res.status === StatusCodes.OK && res.data.collection.length > 0) {
                    query.learnInfo = res.data.collection[0];
                    query.loId = res.data.collection[0].lo_id;
                }
            }


            // console.log(isTokenValid(query.token));

            if (await isTokenValid(query.token) && await ls.isValid(query.learnInfo) &&
                await ls.isValid(query.loId) && await ls.isValid(query.currentUser)) {
                console.log("setLS")
                ls.setToken(query.token);
                ls.setLearnActivityData(query.learnInfo, query.loId, query.currentUser, lang);
                loadStyle();
                setScripts(scriptList);
                setTimeout(loadFrame, 2000);
            }
            else {
                debugger
                console.log("error while setting local storage");
                navigate('/pageNotFound')
            }
        } catch (error) {
            console.log(error);
            navigate('/pageNotFound')
        }
    }


    const stylesheet = [
        `activity/css/mbfonts.css?v.${packageJson.version}`,
        `activity/css/jquery.keypad.css?v.${packageJson.version}`,
        `activity/css/boxy.css?v.${packageJson.version}`,
    ];

    const [scripts, setScripts]: any = useState([])
    const scriptList = [
        // `https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js?v.${packageJson.version}`,
        // `activity/js/jquery.plugin.min.js?v.${packageJson.version}`,
        // `activity/js/jquery.countdownskill.js?v.${packageJson.version}`,
        // `activity/js/jquery.keypad.js?v.${packageJson.version}`,
        // `activity/js/scripts/mb.activity.include.before.js?v.${packageJson.version}`,
        // `activity/js/easeljs/preloadjs-0.3.0.min.js?v.${packageJson.version}`,
        // `activity/js/easeljs/easeljs-0.6.0.min.js?v.${packageJson.version}`,
        // `activity/js/easeljs/tweenjs-0.4.0.min.js?v.${packageJson.version}`,
        // `activity/js/easeljs/movieclip-0.6.0.min.js?v.${packageJson.version}`,
        // `activity/js/modernizr.js?v.${packageJson.version}`,
        // `lessons/scripts/lib/EaseljsCallClass.js?v.${packageJson.version}`,
        // `activity/js/jquery.blockUI.js?v.${packageJson.version}`,
        // `activity/js/scripts/browserCheck.js?v.${packageJson.version}`,
        // `activity/js/scripts/frames/LearnFrame.js?v.${packageJson.version}`,
        // `activity/lessons/Messages.js?v.${packageJson.version}`,
        // `activity/lessons/BufferLoader.js?v.${packageJson.version}`,
        // `activity/js/easeljs/DOMElement.js?v.${packageJson.version}`,
        // `activity/js/scripts/unCaughtException.js?v.${packageJson.version}`,
        // `activity/lessons/GeomLibraryClass.js?v.${packageJson.version}`,
        // `activity/lessons/HelperFunctions.js?v.${packageJson.version}`,
        // `activity/js/jquery.boxy.js?v.${packageJson.version}`,
        // `activity/js/scripts/mLearnFrame.js?v.${packageJson.version}`,
        // `activity/js/scripts/learn.js?v.${packageJson.version}`,
        // `activity/js/scripts/socket.js?v.${packageJson.version}`,
        // `scripts/load.js?v.${packageJson.version}`,
        `playLearn.js?v.${packageJson.version}`,
    ];

    useEffect(() => {
        loadScript()

    }, [scripts.length])

    const loadStyle = () => {
        let playBtn = document.createElement('button');
        playBtn.setAttribute('id', 'activityPlayButton');
        playBtn.setAttribute('style', 'display:none');
        document.body.appendChild(playBtn);

        stylesheet.forEach((s, i) => {
            const style = document.createElement("link");
            style.rel = "stylesheet";
            style.type = "text/css";
            style.id = 'learnStyle' + i;
            style.href = s;
            document.head.appendChild(style);
        })
    }

    const loadScript = () => {
        if (scripts.length == 0)
            return;
        const script = document.createElement("script");
        script.type = 'text/javascript';
        script.src = scripts[0];
        script.id = 'learnScript' + scripts.length;
        // script.async = true
        document.head.appendChild(script);
        if (scripts.length === 26 || scripts.length == 1)
            script.onload = () => {
                sliceScript();
            };
        else
            // setTimeout(() => {
            sliceScript()
        // }, 20)
    }

    const sliceScript = () => {
        let sliceScript = scripts.slice(1);
        setScripts(sliceScript);
        console.log(scripts[0] + " added");
    }

    const clearPrerequisite = () => {
        let styleElms = document.querySelectorAll(`[id^="learnStyle"]`);
        let scriptElms = document.querySelectorAll(`[id^="learnScript"]`);
        console.log(styleElms);
        console.log(scriptElms);
        styleElms.forEach((elm) => {
            elm?.parentNode?.removeChild(elm);
        })
        scriptElms.forEach((elm) => {
            elm?.parentNode?.removeChild(elm);
        })
    }

    const loadFrame = () => {
        let playBtn = document.getElementById("activityPlayButton")
        playBtn?.click();
        setPlayActivity(true);
    }

    return (
        <div className='learnActivityWrapper d-flex  align-items-center justify-content-center'>
            {(!playActivity) && (
                <FASpinner color='#FFF'></FASpinner>
            )}
            {(playActivity) && (<>
                <div className="divece_rotation">
                    <div id="activityWrapper" className="activity_wrapper">
                        <div id="wrapper" style={{ margin: "auto", position: "relative" }}>
                            <canvas id="canvas" width="975px" height="650px" style={{ float: "left" }}>

                            </canvas>

                            <div id="scrible" style={{ display: "none" }}>
                                <div id="scribleBack" className="scrible_pad" style={{ margin: "auto", width: "1060px", height: "708px" }}>
                                    <canvas id="sketchpad" width="1060px" height="708px"></canvas>
                                </div>
                                <div className="button_scrible" //href="#"  onclick="clearCanvas()"
                                    style={{ fontSize: "22px", fontWeight: "bold", top: "40px", left: "200px", position: "absolute", zIndex: "1000", borderRadius: "12px", width: "85px" }}>
                                    Clear</div>
                                <div className="button_scrible" //href="#" onclick="closeCanvas()"
                                    style={{ fontSize: "22px", fontWeight: "bold", position: "absolute", right: "200px", top: "40px", zIndex: "1000", borderRadius: "12px", width: "85px" }}>
                                    Close</div>
                            </div>

                            <div style={{ position: 'relative' }}>
                                <div id='timer'
                                    style={{ width: "10%", float: "left", position: "absolute", fontSize: "40px", color: "#FFF", fontFamily: "Trebuchet MS, Arial, Helvetica, sans-serif", display: 'none' }}>
                                    59</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'none' }}>
                    <audio id="audio"></audio>
                </div>
            </>)}

        </div>
    )
}