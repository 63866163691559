import { ICurrentUser, ILearnInfo } from "../interface/interface";
import packageJson from '../../package.json';
import { decryptData, encryptData } from "../utils/utils";

class LocalStorage {
    isValid = (value: any) => {
        return (value == null || value == undefined || value == '') ? false : true;
    }

    setToken = (token: string) => { localStorage.setItem('authorizationData', token); }
    getToken = () => { return localStorage.getItem('authorizationData') || '' }

    setDBToken = (token: string) => { localStorage.setItem('dbToken', token); }
    getDBToken = () => { return localStorage.getItem('dbToken') || '' }

    updatedStateToLocal = (state: any) => {
        try {
            localStorage.setItem('playState', encryptData(state));

            if (process.env.REACT_APP_ENV === "STAGING")
                localStorage.setItem('viewPlayState', JSON.stringify(state));
        }
        catch (err) {
            //console.log(err);
        }
    }

    getStateFromLocal = () => {
        try {
            this.maintainVersion();
            let state = localStorage.getItem('playState')
            return this.isValid(state) ? decryptData(state!) : undefined;
        }
        catch (err) {
            return undefined;
        }

    }

    maintainVersion = () => {
        let version = localStorage.getItem('version') || '';
        if (version !== packageJson.version) {
            localStorage.clear();
            localStorage.setItem('version', packageJson.version);
        }
    }

    setLearnActivityData = (learnInfo: ILearnInfo, loId: string, currentUser: ICurrentUser, lang = 'en') => {
        try {
            localStorage.setItem('activityId', learnInfo._id);
            localStorage.setItem('unique_id', learnInfo.unique_id);
            localStorage.setItem('loId', loId);
            localStorage.setItem('activity_url', learnInfo.path);
            localStorage.setItem('activity_help', learnInfo.help);
            localStorage.setItem('activity_image', learnInfo.image_path);
            localStorage.setItem('timer', learnInfo.timer_length);
            localStorage.setItem('score_for_completion', learnInfo.score_for_completion || 0);
            localStorage.setItem('activity_type', learnInfo.type);
            localStorage.setItem("activityName", learnInfo.name);
            localStorage.setItem("activity_description", learnInfo.description);
            localStorage.setItem("lessonObjective", loId);
            localStorage.setItem("isHome", 'false');
            localStorage.setItem("currentUser", JSON.stringify(currentUser));
            localStorage.setItem("userCountryCode", currentUser.country_code);
            localStorage.setItem("classroomCountryCode", currentUser.classroom_country_code);
            localStorage.setItem('activityLang', lang);
        }
        catch (err) {
            console.log(err);
        }
    }

    getLearnActivityData = () => {
        try {
            let activityId = localStorage.getItem('activityId');
            let unique_id = localStorage.getItem('unique_id');
            let loId = localStorage.getItem('loId');
            let activity_url = localStorage.getItem('activity_url');
            let activity_help = localStorage.getItem('activity_help');
            let activity_image = localStorage.getItem('activity_image');
            let timer = localStorage.getItem('timer');
            let score_for_completion = localStorage.getItem('score_for_completion');
            let activity_type = localStorage.getItem('activity_type');
            let activityName = localStorage.getItem("activityName");
            let activity_description = localStorage.getItem("activity_description");
            let lessonObjective = localStorage.getItem("lessonObjective");
            let isHome = localStorage.getItem("isHome");
            let currentUser = localStorage.getItem("currentUser");

            return {
                activityId, unique_id, loId, activity_url, activity_help, activity_image,
                timer, score_for_completion, activity_type, activityName, activity_description,
                lessonObjective, isHome, currentUser
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    clearLearnActivityData = () => {
        try {
            localStorage.removeItem('activityId');
            localStorage.removeItem('unique_id');
            localStorage.removeItem('loId');
            localStorage.removeItem('activity_url');
            localStorage.removeItem('activity_help');
            localStorage.removeItem('activity_image');
            localStorage.removeItem('timer');
            localStorage.removeItem('score_for_completion');
            localStorage.removeItem('activity_type');
            localStorage.removeItem("activityName");
            localStorage.removeItem("activity_description");
            localStorage.removeItem("lessonObjective");
            localStorage.removeItem("isHome");
            localStorage.removeItem("currentUser");
            localStorage.removeItem("userCountryCode");
            localStorage.removeItem("classroomCountryCode");
            localStorage.removeItem('activityLang');
        }
        catch (err) {
            console.log(err);
        }
    }

}

export const ls = new LocalStorage
