import './space.css';
import '../../../renderer/renderer.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { renderer } from '../../../renderer/Render';
import { Player } from "@lottiefiles/react-lottie-player";
import { KeyBoardEvents, ReactEvent } from "@mathbuddy/adaptive-practice-events";
import { AppContext, renderedContext, AdaptivePracticeContext, AdaptiveChallengeContext } from "../../../context/context";
import AdaptiveSound from '../sound/AdaptiveSound';
import { MasterPopup } from './popUp/MasterPopup';
import { IAdaptivePracticeContext } from '../interface/interface';
import { FAAngleLeft, FAAngleRight, FAExpand, FARefresh } from '../../../components/atoms/fontawesomeIcon/Fontawesome';
import { toggleFullSceen } from '../../../utils/utils';

export function SpaceFrame() {

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('mousedown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mousedown', handleKeyDown);
    };
  }, [])

  const handleKeyDown = (e: any) => {
    new KeyBoardEvents().handleKeyDown(e);
  }

  const AppState: any = useContext(AppContext);
  const APContext: IAdaptivePracticeContext = useContext(AdaptivePracticeContext);
  const CHContext: any = useContext(AdaptiveChallengeContext);
  const component = APContext.engineObj.component;
  const userResponceRef = useRef(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    let componentlen = Object.keys(component).length;
    // console.log(componentlen)

    if (timeoutRef.current)
      clearTimeout(timeoutRef.current);

    if (componentlen > 0) {
      userResponceRef.current = false;
      scrollUpToEndOfTheQuestion();

      timeoutRef.current = setTimeout(() => {
        if (!userResponceRef.current) {
          playMusic('backgroundSound');
        }
      }, 1000 * 20)
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [APContext.engineObj.sound])

  useEffect(() => {
    if (!APContext.isCloseFromClick && !APContext.isShowStartPopup && APContext.isShowAdaptivePopup)
      playMusic('successSound');
  }, [APContext.isShowAdaptivePopup])

  const [playSound, setPlaySound] = useState({ isPlay: false, type: '' });
  const [soundConfig, setSoundConfig] = useState(true);

  const updateSoundConfig = () => {
    pauseMusic();
    let configStatus = !soundConfig;
    setSoundConfig(configStatus);
  }

  const playMusic = (type: string) => {
    if (soundConfig == true || type === 'coinSound') {
      let playSound = { isPlay: true, type: type + "_" }

      // Copy Start
      // InputBox values are getting lost for adaptive practice while playing sound due to the state update, 
      // So here we are just copying the values before the state change and again setting the same value after the state update so that the user's input doesn't lose. 
      let values: any[] = [];
      let event = { id: '', clickedVal: "text-val-check " }
      let nodelist: any = document.getElementsByClassName(event.clickedVal);
      if (nodelist.length) {
        values = new ReactEvent(event).handleEvent({ classKey: 'inputBoxState', fnName: 'getInputBoxValues' })
      }
      //Copy End

      setPlaySound(playSound);

      setTimeout(() => {
        // set copied values
        if (type === 'coinSound')
          setPlaySound({ isPlay: false, type: "" });
        if (nodelist.length)
          new ReactEvent(event).handleEvent({ classKey: 'inputBoxState', fnName: 'setInputBoxValues' }, values)
      }, 500);

    }
  }

  const pauseMusic = () => {
    let playSound = { isPlay: false, type: '' }
    setPlaySound(playSound);
  }

  const speak = () => {
    let msg = new SpeechSynthesisUtterance();
    let voiceId = 1;
    msg.voice = speechSynthesis.getVoices()[voiceId];
    msg.text = APContext.engineObj.sound;
    msg.volume = 0.5;
    speechSynthesis.speak(msg);
    return false;
  }

  const scrollUpToEndOfTheQuestion = () => {
    const elementStart = document.getElementById('scrollUpToStartOfTheQuestion');
    if (elementStart)
      elementStart.scrollIntoView({ behavior: 'smooth' });
    // console.log("Scrool Start") 
    setTimeout(() => {
      const elementEnd = document.getElementById('scrollUpToEndOfTheQuestion');
      if (elementEnd)
        elementEnd.scrollIntoView({ behavior: 'smooth' });
      // console.log("Scrool End") 
    }, 2000)
  }

  const saveQuestionResponse = async (answerObj: { givenAnswer: any, actualAnswer: any, isCorrectAns: Boolean }, clickCount: number) => {
    // console.log('saveQuestionResponse');
    userResponceRef.current = true;
    pauseMusic();
    let isCorrectAns = answerObj.isCorrectAns;
    if (isCorrectAns)
      playMusic('coinSound');
    if (!isCorrectAns)
      (!CHContext.isChallenge && clickCount === 1) ? playMusic('hintSound') : playMusic('expSound');

    if (APContext.saveQuestionResponse)
      await APContext.saveQuestionResponse(answerObj, clickCount);
  }

  const onFinish = () => {
    userResponceRef.current = true;
    console.log('onFinish');
    APContext.setShowAdaptivePopup();
    playMusic('successSound');
  }

  const loadContent = () => {
    userResponceRef.current = true;
    pauseMusic();

    if (APContext.loadContent)
      APContext.loadContent();
  }

  return (<>
    {(AppState.windowWidth < AppState.windowHeight) && (
      <div className="adaptivePortraitContainer d-flex flex-column justify-content-center align-items-center">
        <h4>Math Buddy works only in landscape mode</h4>
        <span className='text'> Please rotate your device! <br></br>
          (If you are not able to rotate, check your mobile settings and ensure "Auto-rotate screen" is switched on)
        </span>
      </div>
    )}
    {(AppState.windowWidth >= AppState.windowHeight) && (
      <div className="frame-container d-flex flex-column justify-content-around align-items-center" >
        <button className="icon" onClick={() => { APContext.setShowAdaptivePopup(true) }}><img className="close-skill" src="assets/images/themes/space/close.svg"></img></button>

        <div id="frame" >
          <div className="row margin_padding_none_strict" style={{ height: '100%' }}>
            {(APContext.notificationMessage !== '') && (
              <div className='notificationMessage slideInFromLeft'>
                {APContext.notificationMessage}
              </div>
            )}

            <div id="left-menu" className="col-1 d-flex flex-column align-items-start justify-content-start">
              <div className="strick-icon-wrapper main-btn bottom-gradient d-flex align-items-center justify-content-center">
                <a href="#" className="w-100 d-flex align-items-center justify-content-evenly">
                  <div className="img-wrapper" style={{ width: "20%" }}>
                    <img src="assets/images/themes/space/power-icon.svg" alt=""></img>
                  </div>
                  <div className="text-wrapper" style={{ minWidth: "50%" }}>
                    <span>{APContext.streaks}</span>
                  </div>
                </a>
              </div>

              <div className="music-icon-wrapper main-btn-small bottom-gradient d-flex align-items-center justify-content-center" onClick={updateSoundConfig}>
                {(playSound.isPlay) && (<AdaptiveSound type={playSound.type}></AdaptiveSound>)}
                <a href="#">
                  <div className="img-wrapper">
                    <img src={`assets/images/themes/space/music-icon${soundConfig ? '-on' : ''}.svg`} alt=""></img>
                  </div>
                </a>
              </div>

              <div className="user-icon-wrapper main-btn-small bottom-gradient d-flex align-items-center justify-content-center" onClick={speak}>
                <a href="#">
                  <div className="img-wrapper">
                    <img src="assets/images/themes/space/user-icon.svg" alt=""></img>
                  </div>
                </a>
              </div>

              <div className="user-icon-wrapper main-btn-small bottom-gradient d-flex align-items-center justify-content-center" onClick={() => { toggleFullSceen('fullScreen') }}>
                <a href="#">
                  <div className="img-wrapper">
                    <FAExpand color='#fff' size='xl'></FAExpand>
                  </div>
                </a>
              </div>

              {(APContext.options?.isShowReload) && (
                <div className="footer d-flex flex-column align-items-start justify-content-between">
                  <button id="refreshBtn" className="d-flex flex-column align-items-center justify-content-center" onClick={APContext.loadContent}><FARefresh color='#370A52'></FARefresh></button>
                </div>
              )}

            </div>
            <renderedContext.Provider value={{
              explanation: component.explanation,
              hint: component.hint, challenge: {},
              type: 'adaptive-practice',
              saveQuestionResponse: saveQuestionResponse,
              onFinish: onFinish,
              loadContent: loadContent
            }}>
              <div id="question-content" className="col-10 d-flex flex-column">
                {(component.heading) && (renderer(component.heading))}
                <div id="question-content-inner" className='w-100 d-flex flex-column align-items-center justify-content-start'>
                  <div id="scrollUpToStartOfTheQuestion"></div>
                  {
                    Object.keys(component).map((val) => {
                      if (val === 'heading')
                        return;
                      //debugger; 
                      let objRendered;
                      objRendered = renderer(component[val]);
                      //console.log(objRendered);
                      return objRendered;
                    }

                    )
                  }
                  <div id="scrollUpToEndOfTheQuestion"></div>
                </div>
              </div>
            </renderedContext.Provider>
            <div id="right-menu" className="col-1 d-flex flex-column align-items-end justify-content-start">
              <div className="coin-icon-wrapper main-btn bottom-gradient d-flex align-items-center justify-content-center">
                <a href="#" className="w-100 d-flex align-items-center justify-content-evenly">
                  <div className="img-wrapper" style={{ width: "20%", position: "relative" }}>
                    <img src="assets/images/themes/space/coin-icon.svg" alt=""></img>
                    {(APContext.isCoinAnimation) && (
                      <Player id={"coinAnimation"} style={{ "width": "100px", "height": "100px" }} className={"coin-animation"} autoplay speed={1.5} src={"./assets/animation/themes/space/coin.json"}></Player>
                    )}
                  </div>
                  <div className="text-wrapper" style={{ minWidth: "50%" }}>
                    <span>{APContext.coins}</span>
                  </div>
                </a>
              </div>
              {(APContext.meter.level !== '') && (
                <div className="progress-wrapper-2">
                  <span style={{ bottom: (APContext.meter.percentage < 10 ? 0 : APContext.meter.percentage - 10) + "%" }}>{APContext.meter.level}</span>
                  <div className="progress-wrapper">
                    <progress id="progressBar" max="100" value={APContext.meter.percentage}></progress>
                  </div>
                </div>
              )}

              {(APContext.options !== undefined) && (
                <div className="footer d-flex flex-column align-items-evenly justify-content-between">

                  {(APContext.options?.nextPrevBtn !== undefined) && (
                    <div className="d-flex align-items-center justify-content-between">
                      <button id="proviousBtn" className="d-flex flex-column align-items-center justify-content-center" disabled={APContext.options?.nextPrevBtn.current === 0}
                        style={APContext.options?.nextPrevBtn.current === 0 ? { opacity: '0.5' } : {}}
                        onClick={() => { APContext.options?.nextPrevBtn?.setCurrentPos(APContext.options?.nextPrevBtn.current - 1) }}><FAAngleLeft color='#fff'></FAAngleLeft></button>

                      <span style={{ color: '#370A52', fontSize: '1.5vw' }}>{APContext.options?.nextPrevBtn.total > 0 ? APContext.options?.nextPrevBtn.current + 1 : 0}/{APContext.options?.nextPrevBtn.total}</span>

                      <button id="nextBtn" className="d-flex flex-column align-items-center justify-content-center" disabled={APContext.options?.nextPrevBtn.current + 1 === APContext.options?.nextPrevBtn.total}
                        style={APContext.options?.nextPrevBtn.current + 1 === APContext.options?.nextPrevBtn.total ? { opacity: '0.5' } : {}}
                        onClick={() => { APContext.options?.nextPrevBtn?.setCurrentPos(APContext.options?.nextPrevBtn.current + 1) }}><FAAngleRight color='#fff'></FAAngleRight></button>
                    </div>
                  )}

                </div>
              )}

            </div>
          </div>

        </div>

        {(!APContext.isLoader && (APContext.isShowAdaptivePopup || APContext.isShowStartPopup)) && (
          <MasterPopup
            info={APContext.getPopUpInfo()}
            onContinue={APContext.setShowAdaptivePopup}
            onSkip={APContext.onclose}
            onStart={() => { if (APContext.setShowStartPopup !== undefined) { APContext.setShowStartPopup(false) } }}
          />
        )}

        {(APContext.isLoader) && (
          <div className='preloader d-flex align-items-center justify-content-center'>
            <Player id={"loaderAnimation"} autoplay loop style={{ "width": "40%", "height": "30%" }} speed={1} src={"./assets/animation/themes/space/loader.json"}></Player>
          </div>
        )}

      </div>
    )}
  </>)

}